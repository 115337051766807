import _ from 'lodash-es';
import axios from 'axios';
import config from '../config';

export default {
  debug,
  info,
  warn,
  error,
  log: info,
  setCurrentUser,
};

const tagPrefix = 'mls-admin-panel';
const defaultTags = ['mls', tagPrefix];
const exceptionFields = [
  'message',
  'name',
  'response.data',
  'response.status',
  'response.statusText',
  'request.responseURL',
];
const LEVEL_MAP = {
  debug: 20,
  info: 30,
  warn: 40,
  error: 50,
};

let user;

async function send(message, level, ...extraArgs) {
  let messageObj;
  const env = config.APP_ENV || 'prod';
  const envTag = `${tagPrefix}-${env}`;
  const tenant = config.TENANT || 'wmus';
  const tenantTag = `${tagPrefix}-${tenant}`;
  const token = config.LOGGLY_TOKEN || '4a7408b2-648f-40f5-ab4b-c72ab8f11b63';
  const tags = [...defaultTags, envTag, tenantTag];
  if (env === 'prod') {
    tags.push(`${tagPrefix}-${tenant}-prod`);
  } else {
    tags.push(`${tagPrefix}-${tenant}-nonprod`);
  }

  messageObj = toJson(message);
  // Add any extra args onto the object, non-objects get coerced to objects
  // ex: logger.log('my values are', 'foo', 'bar')
  //     becomes:
  //     { message: 'my values are', 0: 'foo', 1: 'bar' }
  extraArgs.forEach((arg, index) => {
    const argObj = toJson(arg, index);
    messageObj = { ...messageObj, ...argObj };
  });

  // Add the user
  messageObj.context = {
    user,
    tenant,
    env,
  };

  messageObj.level = LEVEL_MAP[level];
  messageObj.timestamp = new Date().toISOString();

  const requestConfig = {
    method: 'POST',
    data: JSON.stringify(messageObj),
    url: `https://logs-01.loggly.com/inputs/${token}/tag/${tags.join(',')}/`,
    config: {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  };

  if (['qa', 'prod'].includes(env)) {
    return axios.request(requestConfig);
  }
  if (['local'].includes(env)) {
    // let's fire this off anyhow so we can still see what the logs look like in loggly
    axios.request(requestConfig);
  }
  return console[level](message, ...extraArgs); // eslint-disable-line no-console
}

function toJson(message, index) {
  if (_.isError(message)) {
    return { error: _.pick(message, exceptionFields) };
  }
  if (_.isString(message)) {
    return { [index + 1 || 'message']: message };
  }
  return _.clone(message);
}

async function debug(message, ...args) {
  return send(message, 'debug', ...args);
}

async function info(message, ...args) {
  return send(message, 'info', ...args);
}

async function warn(message, ...args) {
  return send(message, 'warn', ...args);
}

async function error(message, ...args) {
  return send(message, 'error', ...args);
}

function setCurrentUser(newUser) {
  user = _.pick(newUser, ['userId', 'additional']);
  return user;
}
