import _ from 'lodash';
import moment from 'moment-timezone';
import formValidationsUtils from './formValidations.utils';

const { required, allowedChars } = formValidationsUtils;
const onlyNums = allowedChars(`0-9`);
const numsInputsNames = ['store', 'market', 'region'];

export default {
  validatePOTDField,
  validatePOTDAllFields,
  validatePOTDDate,
  removeEmptyWhyItWorksData,
};

/**
 * validatePOTDField()
 * Function to validate an input value
 * returns an Array of objects
 * @params stateKey - the key name
 * @params fieldWithErrorsObj - an object with inputs names as keys and error messages as values
 * @params formState - an object with inputs names as keys and inputs values as values
 */

function validatePOTDField(stateKey, fieldWithErrorsObj, formState) {
  let newError = { ...fieldWithErrorsObj };
  newError = _.omit(newError, stateKey);
  if (!required().isValid(formState[stateKey])) {
    newError = { ...newError, [stateKey]: required().helperText };
  }
  if (
    numsInputsNames.includes(stateKey) &&
    !onlyNums.isValid(formState[stateKey])
  ) {
    newError = { ...newError, [stateKey]: onlyNums.helperText };
  }

  return newError;
}

/**
 * validatePOTDAllFields()
 * Function to validate all inputs values
 * returns an object with the errors
 * @params formState - an object with inputs names as keys and inputs values as values
 */

function validatePOTDAllFields(formState) {
  const newfieldWithErrors = {};
  let newError = {};
  Object.keys(formState).forEach((stateKey) => {
    newError = {
      ...newError,
      ...validatePOTDField(stateKey, newfieldWithErrors, formState),
    };
  });
  return newError;
}

/**
 * validatePOTDDate()
 * Function to check if there is already a POTD scheduled for the selected date,
 * returns a boolean.
 * @params dateValueToCheck - a moment formated date provided by the date picker
 * @params potdListArray - an array of POTDs objects
 */

function validatePOTDDate(dateValueToCheck, potdListArray) {
  const selectedDate = moment(dateValueToCheck).utc().startOf('day');
  const isDateAlreadyScheduled = potdListArray.some((potdItem) => {
    const startDate = moment(potdItem.start_date).utc().startOf('day');
    return selectedDate.isSame(startDate);
  });

  return isDateAlreadyScheduled;
}

/**
 * removeEmptyWhyItWorksData()
 * Function to remove the empty textValues objects from whyItWorks,
 * returns a 'clean' whyItWorks object.
 * @params whyItWorksData - whyItWorks state
 */
function removeEmptyWhyItWorksData(whyItWorksData) {
  const cleanWhyItWorksData = { ...whyItWorksData };
  Object.keys(whyItWorksData).forEach((key) => {
    cleanWhyItWorksData[key] = whyItWorksData[key]
      .filter((whyItWorks) => whyItWorks.textValue !== '')
      .map((whyItWorks) => whyItWorks.textValue);
  });

  return cleanWhyItWorksData;
}
