import { React } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import MaterialTable from 'material-table';

export default function UsersTable({ users }) {
  const navigate = useNavigate();

  const commonCellStyle = {
    padding: '10px',
  };

  const tableOptions = {
    paging: false,
    search: false,
    toolbar: false,
    rowStyle: {
      fontSize: 'small',
    },
    headerStyle: {
      backgroundColor: '#1976d2',
      color: '#FFF',
    },
    tableLayout: 'fixed',
  };

  const columns = [
    { title: 'First Name', field: 'first_name', cellStyle: commonCellStyle },
    { title: 'Last Name', field: 'last_name', cellStyle: commonCellStyle },
    { title: 'Email', field: 'email', cellStyle: commonCellStyle },
    { title: 'Platform ID', field: 'platform_id', cellStyle: commonCellStyle },
  ];

  return (
    <MaterialTable
      title=""
      data={users}
      columns={columns}
      options={tableOptions}
      onRowClick={(event, rowData) => {
        // Copy row data and set checked state
        navigate(`/users/${rowData.platform_id}`);
      }}
    />
  );
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      platform_id: PropTypes.string.isRequired,
    })
  ).isRequired,
};
