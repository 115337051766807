import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

import SpinningSpark from '../shared/components/SpinningSpark';
import PotdService from './POTD.api';
import POTDCandidate from './POTDCandidate';
import POTDContainer from './POTDContainer';
import LoggingService from '../shared/Logging.api';
import styleColors from '../colors.styles';

export default function POTDCandidates() {
  const [potdSearchParam, setPotdSearchParam] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [errorOccurred, setErrorOccurred] = useState(false);

  useEffect(() => {
    fetchCandidates();
  }, []);

  const fetchCandidates = async (searchTerm) => {
    setIsLoading(true);
    return PotdService.getCandidates(searchTerm)
      .then((response) => {
        setCandidates(response.data || []);
        setErrorOccurred(false);
        setIsLoading(false);
      })
      .catch((error) => {
        LoggingService.error('Error getting users', error);
        setCandidates([]);
        setErrorOccurred(true);
        setIsLoading(false);
      });
  };

  // Case for when the form is actually submitted
  function handleSearch(event) {
    if (event) event.preventDefault();
    const searchTerm = potdSearchParam.trim();
    fetchCandidates(searchTerm);
  }

  return (
    <POTDContainer>
      <Grid container xs>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            color={styleColors.base.walmart_blue}
            sx={{ padding: 2 }}
          >
            Post of the Day Candidates
          </Typography>
          <Typography variant="p" sx={{ padding: 2 }}>
            Click on a post to schedule it as a Post of the Day, or search for a
            keyword in the post copy to filter your results.
          </Typography>
        </Grid>
        <Grid item xs={12} className="POTD-candidate-search-box">
          <form onSubmit={handleSearch}>
            <Grid container direction="row" spacing={1} sx={{ padding: 2 }}>
              <Grid container item xs={10} md={6}>
                <TextField
                  id="name"
                  label="Search"
                  placeholder="Search in post copy"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  value={potdSearchParam}
                  onChange={(event) => setPotdSearchParam(event.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {potdSearchParam ? (
                          <IconButton
                            onClick={() => {
                              setPotdSearchParam('');
                              handleSearch();
                            }}
                            disabled={isLoading}
                          >
                            <ClearIcon />
                          </IconButton>
                        ) : (
                          ''
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2} sx={{ alignSelf: 'center' }}>
                <IconButton
                  type="submit"
                  color="primary"
                  size="small"
                  variant="contained"
                >
                  <SearchIcon />
                </IconButton>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <div>
          {' '}
          {errorOccurred && (
            // Display an error message if an error has occurred
            <Alert severity="error" style={{ marginTop: 10 }}>
              There was an error loading Post of the Day Candidates.
            </Alert>
          )}
        </div>
        <Grid item container xs={12} sx={{ pr: 2, pl: 2 }}>
          {isLoading && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
            >
              <SpinningSpark isSpinning />
            </Box>
          )}

          {!isLoading && candidates.length === 0 && (
            <Grid container item xs={12} sx={{ justifyContent: 'center' }}>
              <Typography variant="p">No results found.</Typography>
            </Grid>
          )}

          {!isLoading && candidates.length > 0 && (
            <Grid
              container
              xs={12}
              spacing={2}
              item
              sx={{ justifyContent: 'space-around' }}
            >
              {candidates.map((c) => (
                <POTDCandidate key={c.post_id} data={c} />
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
    </POTDContainer>
  );
}
