import baseConfig from './configs/config.base';

/* eslint-disable import/no-dynamic-require */
const tenant = process.env.REACT_APP_TENANT || 'wmus';
const environment = process.env.REACT_APP_ENV || 'local';
const tenantConfig = require(`./configs/config.${tenant}.${environment}`);

export default {
  ...baseConfig,
  ...tenantConfig,
};
