import React, { useState } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';
import {
  Typography,
  Stack,
  Button,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingButton from '@mui/lab/LoadingButton';
import styleColors from '../colors.styles';

const StickersCreateForm = ({
  categories,
  handleInputChange,
  handleSubmit,
  selectedCategory,
  error,
  submitLoading,
}) => {
  const [evergreen, setEvergreen] = useState(false);
  const [endDate, setEndDate] = useState(null);

  const styles = {
    submitButton: {
      margin: '20px auto 0px auto',
      display: 'block',
      marginTop: '20px',
    },
    buttonActive: {
      backgroundColor: styleColors.base.walmart_blue,
      color: 'white',
    },
    buttonInactive: {
      backgroundColor: styleColors.base.medium_gray,
      color: 'white',
    },
    evergreenUnselected: {
      backgroundColor: styleColors.base.almost_white_blue,
      border: `1px solid ${styleColors.base.almost_white_blue}`,
      '&:hover': {
        backgroundColor: styleColors.base.light_green,
        border: `1px solid ${styleColors.base.green}`,
      },
    },
    evergreenSelected: {
      backgroundColor: styleColors.base.green,
      border: `1px solid ${styleColors.base.green}`,
      '&:hover': {
        backgroundColor: styleColors.base.green,
        border: `1px solid ${styleColors.base.green}`,
      },
    },
  };

  // evergreen needs to be handled differently 'cause it affects styling
  const handleEvergreen = () => {
    // toggle value, save it to state, and send it up to parent component
    const newEvergreenValue = !evergreen;
    const value = newEvergreenValue ? 'now' : endDate;
    setEvergreen(newEvergreenValue);
    handleInputChange('endDate', value);
  };

  return (
    <Stack
      direction="column"
      spacing={2}
      style={{ width: '60%', margin: '0 auto' }}
    >
      <DatePicker
        label="Start at midnight Central on"
        variant="filled"
        fullWidth
        required
        defaultValue={moment()}
        onChange={(event) => handleInputChange('startDate', event)}
      />
      <Stack direction="row" style={{ display: 'flex' }} spacing={4}>
        <DatePicker
          label="End at 11:59 Central on"
          variant="filled"
          fullWidth
          disabled={evergreen}
          onChange={(event) => {
            handleInputChange('endDate', event);
            // hold on to end date in case evergreen is toggled off and this needs restored
            setEndDate(event);
          }}
        />
        <Typography style={{ alignSelf: 'center' }}> OR </Typography>
        <Button
          sx={evergreen ? styles.evergreenSelected : styles.evergreenUnselected}
          onClick={() => handleEvergreen()}
        >
          Evergreen
        </Button>
      </Stack>
      <FormControl variant="filled" fullWidth>
        <InputLabel id="category-select-label">Category</InputLabel>
        <Select
          labelId="category-select-label"
          id="category-select"
          label="Category"
          variant="filled"
          value={selectedCategory}
          fullWidth
          required
        >
          {categories.map((category) => (
            <MenuItem
              key={category.id}
              value={category.name}
              onClick={() => handleInputChange('category', category.name)}
            >
              {category.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <LoadingButton
        onClick={() => handleSubmit()}
        style={
          error.length > 0
            ? { ...styles.submitButton, ...styles.buttonInactive }
            : { ...styles.submitButton, ...styles.buttonActive }
        }
        disabled={!!(error.length > 0) || submitLoading}
        loading={submitLoading}
      >
        <Typography>Submit</Typography>
      </LoadingButton>
    </Stack>
  );
};

export default StickersCreateForm;

StickersCreateForm.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  submitLoading: PropTypes.bool.isRequired,
};
