import React, { useState, useEffect } from 'react';
import { ImageList, ImageListItem, Tooltip, Typography } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import _ from 'lodash';
import PropTypes from 'prop-types';
import Sticker from './Sticker';

const StickerList = ({ stickers, handleSelect, selectedStickers }) => {
  const [filteredStickers, setFilteredStickers] = useState(stickers);

  useEffect(() => {
    setFilteredStickers(
      _.orderBy(stickers, ['active', 'created_at'], ['desc', 'desc'])
    );
  }, [stickers]);

  const styles = {
    imageList: {
      width: '100%',
      minWidth: '550px',
      height: '95%',
      maxHeight: `${stickers.length * 40}px`,
      backgroundColor: 'black',
      padding: '20px',
      minHeight: '60vh',
      marginTop: 0,
      borderRadius: '3px',
      color: 'white',
    },
    tooltip: {
      position: 'absolute',
      top: '0',
      right: '0',
      zIndex: 100,
    },
    selected: {
      boxShadow: '0 0 5px 3px #a3a3a3',
      borderRadius: '3px',
      backgroundColor: 'rgba(163, 163, 163, 0.55)',
      opacity: 1,
    },
  };

  const renderSticker = (sticker) => {
    return (
      <ImageListItem
        key={sticker.id}
        onClick={() => {
          handleSelect(sticker);
        }}
      >
        {_.get(sticker, 'active', true) === true ? (
          <Sticker
            status={_.find(selectedStickers, sticker) ? 'selected' : 'active'}
            sticker={sticker}
          />
        ) : (
          <div
            style={{
              ...(_.find(selectedStickers, sticker) && styles.selected),
            }}
          >
            <Tooltip title="Inactive sticker" style={styles.tooltip}>
              <VisibilityOffIcon sx={{ color: 'white' }} />
            </Tooltip>
            <Sticker status="inactive" sticker={sticker} />
          </div>
        )}
      </ImageListItem>
    );
  };

  return (
    <>
      {stickers.length === 0 ? (
        <Typography sx={styles.imageList}>
          No active stickers found for this category. <br />
          <br />
          Please add a sticker, or toggle the {`"`}Show inactive stickers{`"`}{' '}
          switch to view expired or upcoming stickers.
        </Typography>
      ) : (
        <ImageList sx={styles.imageList} cols={5} rowHeight={100} gap={40}>
          {filteredStickers.map((sticker) => renderSticker(sticker))}
        </ImageList>
      )}
    </>
  );
};
StickerList.propTypes = {
  stickers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSelect: PropTypes.func.isRequired,
  selectedStickers: PropTypes.arrayOf(PropTypes.shape({})),
};

StickerList.defaultProps = {
  selectedStickers: [],
};

export default StickerList;
