import React from 'react';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styleColors from '../colors.styles';
import POTDCard from './POTDListCard';

const styles = {
  weekContainer: {
    opacity: 1,
    borderRadius: '4px',
    boxShadow: '0px 3px 6px #00000029',
    backgroundColor: '#FFFFFF',
    marginBottom: '2vh',
  },
  weekNumber: {
    backgroundColor: '#FFFFFF',
    color: styleColors.base.walmart_blue,
    textAlign: 'left',
    fontSize: '2.5rem',
    padding: '1vw',
    borderRadius: '4px 4px 0 0',
    marginBottom: 0,
  },
  childrenContainer: {
    padding: '0 1vw 1vw 1vw',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gridColumnGap: '1vw',
    gridRowGap: '1vw',
  },
};

export default function POTDWeek({ potdList, week, deletePotd }) {
  return (
    <>
      <div className="POTD-week-container" style={styles.weekContainer}>
        <div className="POTD-week-header">
          <Typography variant="h2" style={styles.weekNumber}>
            Walmart Week {week}
          </Typography>
        </div>
        <div className="POTD-day-children" style={styles.childrenContainer}>
          {_.map(potdList, (potd) => {
            return (
              <POTDCard
                potd={potd}
                key={potd.start_date}
                deletePotd={deletePotd}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

POTDWeek.propTypes = {
  potdList: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  week: PropTypes.number.isRequired,
  deletePotd: PropTypes.func,
};
POTDWeek.defaultProps = {
  deletePotd: () => {},
};
