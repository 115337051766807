import React from 'react';

import { Button, Grid, Modal, Paper, Tooltip, Typography } from '@mui/material';
import { createStyles } from '@mui/styles';
import PropTypes from 'prop-types';

export default function CustomModal({
  isOpen,
  setOpen,
  title,
  children,
  closeText,
  closeColor,
}) {
  const styles = createStyles({
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 800,
      width: '100vw',
      maxHeight: '100vh',
      overflow: 'auto',
      padding: 4,
      border: '2px solid #666',
      boxShadow: 24,
    },
  });

  return (
    <Modal
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') setOpen(false);
      }}
    >
      <Paper sx={styles.modal}>
        <Grid container id="createEditModal_header" spacing={2}>
          <Grid item xs>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid item xs="auto" align="right">
            <Tooltip>
              <Button
                type="button"
                variant="outlined"
                color={closeColor}
                size="large"
                onClick={() => {
                  setOpen(false);
                }}
              >
                {closeText}
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        {children}
      </Paper>
    </Modal>
  );
}

CustomModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.shape({}).isRequired,
  closeText: PropTypes.string,
  closeColor: PropTypes.string,
};

CustomModal.defaultProps = {
  isOpen: false,
  closeText: 'Discard',
  closeColor: 'error',
};
