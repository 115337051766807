const PRIMARY_WALMART = {
  // Grouped by color family, in ROYGBIV order
  // blues
  walmart_blue: '#0071cd',
  dark_blue: '#064f8e',

  // light blues
  barely_blue: '#f8fafc',
  almost_white_blue: '#F2F6FA',
  very_light_blue: '#DEE8F4',
  light_blue: '#78b9e7',
  dark_light_blue: '#1188c8',

  // pinks
  pink: '#eb148d',
  dark_pink: '#b51e6d',

  // yellows
  light_yellow: '#F4F0E7',
  spark_yellow: '#ffc220',
  dark_yellow: '#f79428',

  // greens
  light_green: '#289f3b26',
  green: '#76c043',
  dark_green: '#54a546',

  // oranges
  orange: '#f47521',
  dark_orange: '#e54e26',

  // reds
  red: '#ee3b2a',
  dark_red: '#cb2022',
  light_red: '#c6222226',

  // purples
  light_purple: '#d9a3dd',

  // grays
  very_light_gray: '#828ba226',
  light_gray: '#c1c1c1',
  medium_gray: '#828282',
  dark_gray: '#737373',
  black: '#000',

  // variables named by function
  inactive: '#e0e0e0',
  light: '#ffffff',
  active: '#ffc220',
  error: '#f2dcda',
};

const SOCIAL = {
  facebook: '#3b5998',
  instagram: '#E8446F',
};

const TEXT = {
  body: '#111111',
  gray: '#666666',
  fbLightGray: '#9197a3',
};

export default {
  base: PRIMARY_WALMART,
  social: SOCIAL,
  text: TEXT,
};
