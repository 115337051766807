import _ from 'lodash';

export default {
  required,
  dateSequence,
  allowedChars,
  childValidation,
};

function required() {
  return {
    isValid: (val) => val !== null && val !== undefined && val !== '',
    helperText: 'This field is required.',
  };
}

function dateSequence() {
  return {
    isValid: (momentStart, momentEnd) => momentStart.isBefore(momentEnd),
    helperText: 'Start date must be before end date.',
  };
}
function allowedChars(chars) {
  return {
    isValid: (val) => {
      const regex = new RegExp(`^[${chars}]*$`);
      return regex.test(val);
    },
    helperText: `This field may only contain the following characters: ${chars}`,
  };
}
function childValidation() {
  return {
    isValid: (areChildrenValid) => _.every(areChildrenValid),
    helperText: 'This form has errors. Please fix them before submitting.',
  };
}
