import AppApi from '../shared/App.api';

export default {
  getReviewVideos,
  approvePost,
  rejectPost,
};

const adminBaseUrl = '/admin';

function getReviewVideos() {
  return AppApi.get(`${adminBaseUrl}/review`);
}

function approvePost(postId, body) {
  return AppApi.postJson(`${adminBaseUrl}/review/${postId}/approve`, body);
}

function rejectPost(postId, body) {
  return AppApi.postJson(`${adminBaseUrl}/review/${postId}/reject`, body);
}
