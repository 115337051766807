export default {
  getReadableNum,
};
/**
 * This function is used to convert a number to an easily readable number. For example it will turn
 * 1000000 into 1M. This prevents the page from having to expand to display different magnitudes of numbers.
 * @params num - the number to convert to a readable number
 * @params round - the degree to round the number to ie makeReadable(100.456, 1) = 100.5
 * @params maxDigits (optional) - the max number of digits to display
 *                                ie makeReadable(100.456, 2, 3) = 100, makeReadable(100.456, 3, 10) = 100.456
 */
export function getReadableNum(num, round = 1, maxDigits) {
  let reducedNum = num;
  let abbr = '';
  if (num >= 1000000000) {
    reducedNum = num / 1000000000;
    abbr = 'B';
  } else if (num >= 1000000) {
    reducedNum = num / 1000000;
    abbr = 'M';
  } else if (num >= 1000) {
    reducedNum = num / 1000;
    abbr = 'K';
  } else {
    reducedNum = num / 1;
  }
  const numString = `${reducedNum
    .toLocaleString('en-US', { maximumFractionDigits: round })
    .replace(/\.0+$/, '')}`;
  return maxDigits && numString.length > maxDigits
    ? `${parseFloat(numString).toLocaleString('en-US', {
        maximumSignificantDigits: maxDigits,
      })}${abbr}`
    : `${numString}${abbr}`;
}
