import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { createStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import _ from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styleColors from '../../../colors.styles';
import CustomTableRow from './CustomTableRow';

/**
 * @typedef {Object} Columns
 * @property {string} id - unique id for the column
 * @property {string} name - name of the column
 * @property {string} path - path to the value in the priority object
 * @property {function} display - function to display the value (optional)
 * @property {function} filter - function to filter the priority (optional)
 * @property {function} sort - function to sort the priority (optional)
 * @property {boolean} isDate - whether the column is a date (optional)
 */

/**
 * @typedef {Object} Actions
 * @property {string} id - unique id for the action
 * @property {string} name - name of the action
 * @property {string} icon - icon to display
 * @property {string} textColor - color of the text
 * @property {function} onClick - function to call when the action is clicked
 */

export default function CustomTable({
  data,
  columns,
  actions,
  omniSearchValue, // if we want to generalize, `omniSearchValue` and `hideFuturePriorities` should be an array of filters
  hideFuturePriorities,
}) {
  const DEFAULT_ORDER = { asc: true, selected: false };
  const styles = createStyles({
    header: {
      light: {
        backgroundColor: '#FFF',
        color: styleColors.base.walmart_blue,
      },
      button: {
        textTransform: 'none',
        display: 'flex',
        justifyContent: 'start',
        whiteSpace: 'nowrap',
        minWidth: 'max-content',
      },
    },
  });
  const now = new Date();
  const initialOrder = columns.reduce(
    (acc, column) => ({
      ...acc,
      [column.id]: column.orderInfo || DEFAULT_ORDER,
    }),
    {}
  );
  const initialSearchBy = columns.reduce(
    (acc, column) => ({
      ...acc,
      [column.id]: column.isDate ? null : '',
    }),
    {}
  );
  const [ascOrDesc, setAscDesc] = useState(initialOrder);
  const [searchBy, setSearchBy] = useState(initialSearchBy);
  const [dataList, setDataList] = useState(data);

  useEffect(() => {
    setDataList(data);
  }, [data]);

  function mainFilter(priority) {
    const containsSearchTerm = priority.searchable
      .toLowerCase()
      .includes(omniSearchValue.toLowerCase());
    const violatesFutureFilter =
      hideFuturePriorities && new Date(priority.startDate) > now;

    return containsSearchTerm && !violatesFutureFilter;
  }

  function filterByColumn() {
    const filteredList = _.filter(dataList, (value) => {
      return _.every(columns, (column) => {
        const searchText = searchBy[column.id];
        if (!searchBy[column.id]) return true;
        if (_.has(column, 'filter')) {
          return column.filter(value, searchText);
        }
        if (column.isDate) {
          return moment(_.get(value, column.path)).isSame(searchText, 'day');
        }
        return _.get(value, column.path)
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
    });
    return filteredList;
  }

  function setOrderAndSelectColumn(key) {
    setAscDesc({
      ...initialOrder,
      [key]: { asc: !ascOrDesc[key].asc, selected: true },
    });
  }

  function sortBy(key) {
    const column = _.find(columns, { id: key });
    if (column.isDate) {
      setDataList(
        dataList.sort((a, b) => {
          return ascOrDesc[key].asc
            ? new Date(_.get(b, column.path)) - new Date(_.get(a, column.path))
            : new Date(_.get(a, column.path)) - new Date(_.get(b, column.path));
        })
      );
    } else {
      setDataList(
        _.orderBy(
          dataList,
          [
            column.sort
              ? (p) => _.toLower(column.sort(p))
              : (p) => _.toLower(_.get(p, column.path)),
          ],
          ascOrDesc[key].asc ? 'asc' : 'desc'
        )
      );
    }
    setOrderAndSelectColumn(key);
  }

  return (
    <TableContainer component={Paper} overflow="auto">
      <Table aria-label="custom table" stickyHeader>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <TableCell key={column.id}>
                    <Stack spacing={1}>
                      <Button
                        sx={[styles.header.light, styles.header.button]}
                        onClick={() => sortBy(column.id)}
                        endIcon={
                          ascOrDesc[column.id].selected &&
                          (ascOrDesc[column.id].asc ? (
                            <ArrowDownward />
                          ) : (
                            <ArrowUpward />
                          ))
                        }
                      >
                        {column.name}
                      </Button>
                      {column.isDate ? (
                        <DatePicker
                          slotProps={{
                            textField: { size: 'small', placeholder: '' },
                            actionBar: {
                              actions: ['clear'],
                            },
                          }}
                          sx={{
                            width: '165px',
                          }}
                          value={searchBy[column.id]}
                          onChange={(value) =>
                            setSearchBy({ ...searchBy, [column.id]: value })
                          }
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          size="small"
                          value={searchBy[column.id]}
                          onChange={(e) =>
                            setSearchBy({
                              ...searchBy,
                              [column.id]: e.target.value,
                            })
                          }
                        />
                      )}
                    </Stack>
                  </TableCell>
                );
              })}
              {!_.isEmpty(actions) && (
                <TableCell align="center" sx={styles.header.light}>
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
        </LocalizationProvider>
        <TableBody>
          {_.map(_.filter(filterByColumn(), mainFilter), (value) => (
            <CustomTableRow
              key={value.id}
              row={value}
              columns={columns}
              actions={actions}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CustomTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
  omniSearchValue: PropTypes.string.isRequired,
  hideFuturePriorities: PropTypes.bool.isRequired,
};

CustomTable.defaultProps = {
  actions: [],
};
