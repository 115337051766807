/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, TextField, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import PropTypes from 'prop-types';

import _ from 'lodash';

import config from '../../config';
import ObjectUtils from '../../shared/utils/object.utils';
import FormValidationsUtils from '../../shared/utils/formValidations.utils';
import PriorityLanguageCopy from '../PriorityLanguageCopy.class';

const { DEFAULT_LANGUAGE } = config;
const { isObjectBlank } = ObjectUtils;
const { required } = FormValidationsUtils;

/*
  If we have time, we should try to create a common abstraction from `PriorityGetStartedStepsForm` 
  and `PriorityCallsToActionForm`. They both allow the user to add/remove rows of data, and each 
  row can have any number of string inputs. As written, they cannot handle nested objects or other
  input types, but that could be changed.

  I have left comments in the code bringing attention to some things we should consider if we
  generalize this component.
*/

export default function PriorityGetStartedStepsForm({
  initialState,
  onStateChanged,
  isValidating,
  langKey,
  spacing,
}) {
  const [getStartedSteps, setGetStartedSteps] = useState(
    initialState || [PriorityLanguageCopy.initGetStartedStep()]
  );
  const [validationErrors, setValidationErrors] = useState([]);

  function validate() {
    const newErrors = [];

    if (isValidating) {
      if (getStartedSteps > 1 || !isObjectBlank(_.first(getStartedSteps))) {
        getStartedSteps.forEach((step, index) => {
          // consider receiving validation criteria as a prop
          if (!required().isValid(step)) {
            newErrors[index] = required().helperText;
          }
        });
      }
    }

    setValidationErrors(newErrors);
    return _.isEmpty(newErrors);
  }

  useEffect(() => {
    onStateChanged(getStartedSteps, validate());
  }, [getStartedSteps, isValidating]);

  function appendEmptyItem() {
    setGetStartedSteps([
      ...getStartedSteps,
      PriorityLanguageCopy.initGetStartedStep(),
    ]);
  }

  // if we anticipate dealing with nested objects, consider receiving this function as a prop
  function setItemAt(index, newValue) {
    const newGetStartedSteps = [...getStartedSteps];
    newGetStartedSteps[index] = newValue;
    setGetStartedSteps(newGetStartedSteps);
  }

  function removeItemAt(index) {
    const newGetStartedSteps = [...getStartedSteps];
    newGetStartedSteps.splice(index, 1);
    setGetStartedSteps(newGetStartedSteps);
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">How to Complete ({langKey})</Typography>
      </Grid>

      {getStartedSteps.map((step, index) => (
        <Grid
          key={`getStarted_${index}`}
          item
          container
          spacing={spacing}
          xs={12}
        >
          <Grid item xs>
            <TextField
              label={`Step ${index + 1}`}
              value={step}
              onChange={(e) => setItemAt(index, e.target.value)}
              fullWidth
              multiline
              error={!!validationErrors[index]}
              helperText={validationErrors[index]}
            />
          </Grid>

          <Grid item xs="auto" display="flex" alignItems="center">
            <IconButton
              type="button"
              variant="text"
              color="error"
              size="small"
              onClick={() => removeItemAt(index)}
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button
          type="button"
          variant="text"
          color="success"
          startIcon={<Add />}
          size="small"
          onClick={() => appendEmptyItem()}
        >
          add {getStartedSteps.length ? 'another' : 'a'} step
        </Button>
      </Grid>
    </>
  );
}

PriorityGetStartedStepsForm.propTypes = {
  initialState: PropTypes.arrayOf(PropTypes.string), // we might need `PropTypes.object` to generalize
  onStateChanged: PropTypes.func.isRequired,
  isValidating: PropTypes.bool,
  spacing: PropTypes.number,
  langKey: PropTypes.string, // this is only used in the title - consider receiving the title as a prop
};

PriorityGetStartedStepsForm.defaultProps = {
  initialState: null,
  isValidating: false,
  langKey: DEFAULT_LANGUAGE,
  spacing: 1,
};
