/* eslint no-alert: 0 */
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Grid,
  Button,
  Typography,
  TextField,
  Alert,
  Stack,
} from '@mui/material';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import UserService from './Users.api';
import UsersTable from './UsersTable';
import SpinningSpark from '../shared/components/SpinningSpark';
import LoggingService from '../shared/Logging.api';

export default function Users() {
  // get search param from url
  const [querySearchParams, setQuerySearchParams] = useSearchParams('');
  const [userSearchParam, setUserSearchParam] = useState(
    querySearchParams.get('search') || ''
  );
  const [loading, setLoading] = useState(false);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const [users, setUsers] = useState([]);
  const styles = {
    text: {
      textAlign: 'center',
    },
  };

  const MIN_SEARCH_LENGTH = 3;

  // case for searching when the page is initially loaded
  useEffect(() => {
    // If we already have a search param (from the home page search), then we need to do a search
    if (userSearchParam) {
      userSearch(userSearchParam);
    }
  }, []);

  // Case for searching when the url search param changes
  // This effect is triggered after the call to setQuerySearchParams is made inside of userSearch
  useEffect(() => {
    // By this point, the desired search param has been either placed in the url, or updated inside the
    // url by using the navigate backwards/forwards in history buttons in the browser.
    const searchTerm = querySearchParams.get('search') || '';
    if (!searchTerm) return;

    setLoading(true);

    UserService.getUsers(searchTerm)
      .then((response) => {
        setUsers(response.data.data || []);
        setErrorOccurred(false);
        setLoading(false);
      })
      .catch((error) => {
        LoggingService.error('Error getting users', error);
        setUsers([]);
        setErrorOccurred(true);
        setLoading(false);
      });

    setUserSearchParam(searchTerm);
  }, [querySearchParams]);

  // This function is triggered after actually submitting a search
  // form, either on the users page or from the home page.
  function userSearch(searchTerm) {
    if (searchTerm.length === 0) {
      return;
    }

    if (searchTerm.length < MIN_SEARCH_LENGTH) {
      alert(
        `Please enter at least ${MIN_SEARCH_LENGTH} characters to search for a user.`
      );
      return;
    }

    // Set the search param in the url.
    // This will trigger the useEffect that is listening for changes to the search param, updating the users list
    setQuerySearchParams({ search: searchTerm });
  }

  // Case for when the form is actually submitted
  function handleSearch(event) {
    if (event) event.preventDefault();
    const searchTerm = userSearchParam.trim();
    userSearch(searchTerm);
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Stack spacing={1}>
          <Typography variant="button" sx={styles.text}>
            Search for a name, platform id, or email.
          </Typography>
          <Grid item>
            <form onSubmit={handleSearch}>
              <Grid
                container
                direction="row"
                spacing={1}
                sx={{ justifyContent: 'center' }}
              >
                <Grid item xs={6}>
                  <TextField
                    id="name"
                    label="Search..."
                    placeholder="Search..."
                    variant="outlined"
                    fullWidth
                    autoFocus
                    value={userSearchParam}
                    onChange={(event) => setUserSearchParam(event.target.value)}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    fullWidth
                    sx={{ height: '100%' }}
                    startIcon={<PersonSearchIcon />}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Stack>
      </Grid>
      <Grid item>
        <Grid container sx={{ justifyContent: 'center' }}>
          {(() => {
            if (errorOccurred) {
              // Display an error message if an error has occurred
              return (
                <Alert severity="error" style={{ marginTop: 10 }}>
                  There was an error loading the users.
                </Alert>
              );
            }
            if (loading) {
              // Display a spinner if data is still loading
              return <SpinningSpark isSpinning={loading} height="50" />;
            }
            if (!loading && _.isEmpty(userSearchParam) && users.length === 0) {
              // Display a message prompting the user to search if there is no search query and no users have been found
              return (
                <Typography sx={styles.text}>
                  Search for a user in the input above.
                </Typography>
              );
            }
            if (!loading && !_.isEmpty(userSearchParam) && users.length === 0) {
              // Display a message indicating that no users were found if there is a search query and no users match the search criteria
              return <Typography sx={styles.text}>No users found.</Typography>;
            }
            if (!loading && users.length > 0) {
              // Display a table of users if users have been found and the data has finished loading
              return (
                <Grid xs={8}>
                  <UsersTable users={users} />
                </Grid>
              );
            }
            // If none of the above conditions are met, return an empty fragment
            return <> </>;
          })()}
        </Grid>
      </Grid>
    </Grid>
  );
}
