import React from 'react';
import PropTypes from 'prop-types';
import associateSpark from '../../assets/associate-spark.png';

export default function SpinningSpark({
  height,
  width,
  isSpinning,
  marginTop,
}) {
  return (
    <img
      style={{
        animation: isSpinning ? 'rotation 2s infinite linear' : 'none',
        marginTop,
      }}
      src={associateSpark}
      height={height}
      width={width}
      alt="Spark"
    />
  );
}

SpinningSpark.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  isSpinning: PropTypes.bool,
  marginTop: PropTypes.string,
};

SpinningSpark.defaultProps = {
  height: '40',
  width: 'auto',
  isSpinning: true,
  marginTop: '5vh',
};
