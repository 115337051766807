import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { Warning } from '@mui/icons-material';
import PropTypes from 'prop-types';

import _ from 'lodash';

import config from '../../config';
import FormValidationsUtils from '../../shared/utils/formValidations.utils';
import PriorityLanguageCopyForm from './PriorityLanguageCopyForm';
import PriorityLanguageCopy from '../PriorityLanguageCopy.class';
import Priority from '../Priority.class';

const { DEFAULT_LANGUAGE } = config;
const { childValidation } = FormValidationsUtils;

/*
  If we find that we have other requirements for multi-language editing, it would
  be worth decoupling this from `PriorityLanguageCopyForm` by allowing the caller
  to specify the sub-component to render.
*/
export default function PriorityLanguagesForm({
  initialLanguages,
  allowedLanguages,
  onStateChanged,
  isValidating,
  spacing,
}) {
  const initialLanguageSelection =
    Priority.defaultOrFirstLanguage(initialLanguages)?.languageName ??
    DEFAULT_LANGUAGE;

  const [language, setLanguage] = useState(initialLanguageSelection);
  const [languages, setLanguages] = useState({
    ...Priority.initLanguages(),
    ...initialLanguages,
  });

  const [validationErrors, setValidationErrors] = useState({});

  // don't validate empty children if at least one is being filled out
  function shouldChildValidate(key) {
    return (
      isValidating &&
      (!PriorityLanguageCopy.isEmpty(languages[key]) ||
        _.some(languages, PriorityLanguageCopy.isEmpty))
    );
  }

  const [areChildrenValid, setAreChildrenValid] = useState(
    allowedLanguages.reduce(
      (acc, lang) => ({
        ...acc,
        [lang]:
          !shouldChildValidate(lang) ||
          !PriorityLanguageCopy.isEmpty(initialLanguages[lang]),
      }),
      {}
    )
  );

  function onLanguageStateChanged(langKey, newState, isStateValid) {
    setLanguages({
      ...languages,
      [langKey]: newState,
    });
    if (!_.isEmpty(newState)) {
      setAreChildrenValid({
        ...areChildrenValid,
        [langKey]: isStateValid,
      });
    }
  }

  function validate() {
    const newErrors = {};

    if (isValidating) {
      if (_.some(languages, PriorityLanguageCopy.isEmpty)) {
        newErrors.summary = 'You need to fill all the languages';
      }
      if (!childValidation().isValid(areChildrenValid)) {
        newErrors.children = childValidation().helperText;
      }
    }

    setValidationErrors(newErrors);
    return _.isEmpty(newErrors);
  }

  useEffect(() => {
    onStateChanged(languages, validate());
  }, [languages, isValidating]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Language-specific copy</Typography>
      </Grid>

      {_.has(validationErrors, 'summary') && (
        <Grid item xs={12}>
          <Typography color="error">{validationErrors.summary}</Typography>
        </Grid>
      )}

      <Grid item xs={12}>
        <ToggleButtonGroup
          exclusive
          color="primary"
          fullWidth
          value={language}
          onChange={(e, newVal) => newVal && setLanguage(newVal)}
        >
          {allowedLanguages.map((langKey) => (
            <Tooltip
              key={`tooltip_${langKey}`}
              title={areChildrenValid[langKey] ? '' : validationErrors.children}
            >
              <ToggleButton
                key={`toggle_${langKey}`}
                size="small"
                value={langKey}
                selected={language === langKey}
              >
                {!areChildrenValid[langKey] && <Warning color="error" />}
                {langKey}
                {!PriorityLanguageCopy.isEmpty(languages[langKey]) && '...'}
              </ToggleButton>
            </Tooltip>
          ))}
        </ToggleButtonGroup>
      </Grid>

      {allowedLanguages.map((langKey) => (
        <Grid key={`form_${langKey}`} item container xs={12} spacing={spacing}>
          <PriorityLanguageCopyForm
            langKey={langKey}
            isHidden={langKey !== language}
            initialLanguageCopy={languages[langKey]}
            isValidating={shouldChildValidate(langKey)}
            onStateChanged={(newState, isStateValid) =>
              onLanguageStateChanged(langKey, newState, isStateValid)
            }
            spacing={spacing}
          />
        </Grid>
      ))}
    </>
  );
}

PriorityLanguagesForm.propTypes = {
  allowedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onStateChanged: PropTypes.func.isRequired,
  initialLanguages: PropTypes.shape({}),
  isValidating: PropTypes.bool,
  spacing: PropTypes.number,
};

PriorityLanguagesForm.defaultProps = {
  initialLanguages: {},
  isValidating: false,
  spacing: 1,
};
