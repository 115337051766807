/* eslint-disable no-alert */
import React, { useEffect, useState, useContext } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import _ from 'lodash';
import StoreService from './Stores.api';
import StoresTable from './StoresTable';
import LoggingService from '../shared/Logging.api';
import SpinningSpark from '../shared/components/SpinningSpark';
import { AlertsContext } from '../shared/alerts/Alerts.context';

export default function Stores() {
  const confirm = useConfirm();
  const { showAlert } = useContext(AlertsContext);
  const [stores, setStores] = useState([]);
  const [errorOccurred, setErrorOccurred] = useState(false);

  useEffect(() => {
    function getStores() {
      StoreService.getStores()
        .then((response) => {
          setStores(
            response.data.map((store) => {
              return { ...store, region: parseInt(store.region, 10) };
            })
          );
          setErrorOccurred(false);
        })
        .catch((error) => {
          LoggingService.error('Error getting stores', error);
          setErrorOccurred(true);
        });
    }
    getStores();
  }, []);

  const pauseStores = async (storeNums = [], unpause = false) => {
    const pauseUnpause = unpause ? 'unpause' : 'pause';
    try {
      await confirm({
        title: `Are you sure you want to ${pauseUnpause} ${storeNums.length} store(s)?`,
        confirmationText: 'Yes',
        cancellationText: 'No',
      });
    } catch (err) {
      return;
    }
    try {
      const response = await StoreService[`${pauseUnpause}Stores`](storeNums);
      const { updatedStores, failedStores } = response;

      // This is temporary to have more visibility on the results
      const successMsg = updatedStores.length
        ? `Updated stores: ${updatedStores.join(', ')}.\n`
        : '';
      const errorMsg =
        failedStores.length > 0
          ? `Failed to ${pauseUnpause} all channels for the following stores:` +
            ` ${failedStores.join(', ')}\n` +
            `Please try again or contact engineering.`
          : '';
      let alertType = 'success';
      if (failedStores.length > 0 && updatedStores.length === 0) {
        alertType = 'error';
      } else if (failedStores.length > 0) {
        alertType = 'warning';
      }
      showAlert(`${successMsg}${errorMsg}`, alertType);

      const newStores = stores.map((store) => {
        if (updatedStores.includes(store.store_number)) {
          return { ...store, is_paused: !unpause };
        }
        return store;
      });
      setStores(newStores);
    } catch (err) {
      showAlert(err.message, 'error');
    }
  };

  const unpauseStores = (storeIds = []) => pauseStores(storeIds, true);

  return (
    <>
      <Grid item xs={12} style={{ marginTop: 10 }}>
        <Typography variant="h4" align="center">
          Stores
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {!_.isEmpty(stores) ? (
          <StoresTable
            stores={stores}
            pauseStores={pauseStores}
            unpauseStores={unpauseStores}
          />
        ) : (
          (errorOccurred && (
            <Typography textAlign="center">
              Failed to get stores, please reload the page or contact
              engineering for assistance
            </Typography>
          )) || (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
            >
              <SpinningSpark isSpinning={_.isEmpty(stores)} />
            </Box>
          )
        )}
      </Grid>
    </>
  );
}
