import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const UploadButton = ({
  handleFileUpload,
  text,
  accept,
  style,
  buttonStyle,
}) => {
  const styles = {
    visuallyHidden: {
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: 1,
      overflow: 'hidden',
      position: 'absolute',
      bottom: 0,
      left: 0,
      whiteSpace: 'nowrap',
      width: 1,
    },
    uploadButton: {
      width: '50%',
      margin: '20px auto 20px auto',
      display: 'flex',
    },
  };

  return (
    <div style={{ minHeight: '100px', ...style }}>
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<CloudUploadIcon />}
        style={{ ...styles.uploadButton, ...buttonStyle }}
      >
        {text}
        <input
          type="file"
          accept={accept}
          onChange={(event) => {
            handleFileUpload(event);
            // Clear the file input after the file has been uploaded
            // to avoid WMTA-7766
            // eslint-disable-next-line no-param-reassign
            event.target.value = '';
          }}
          multiple
          style={styles.visuallyHidden}
        />
      </Button>
    </div>
  );
};

export default UploadButton;

UploadButton.propTypes = {
  handleFileUpload: PropTypes.func.isRequired,
  text: PropTypes.string,
  accept: PropTypes.string,
  style: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  buttonStyle: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
};

UploadButton.defaultProps = {
  accept: 'image/*',
  style: {},
  buttonStyle: {},
  text: '',
};
