import _ from 'lodash';

export default {
  formatISO,
  isEmptyDeep,
  arrayToString,
  stringToArray,
};

function formatISO(isoDate) {
  return new Date(isoDate).toLocaleString('en-US', {
    timeZone: 'America/New_York',
  });
}

/*
  Recursively checks if an object, array, or string is empty.
*/
function isEmptyDeep(obj) {
  if (_.isString(obj)) {
    return obj.trim().length === 0;
  }
  if (_.isNumber(obj) || _.isBoolean(obj)) {
    return false;
  }
  if (_.isObjectLike(obj)) {
    return _.every(obj, isEmptyDeep);
  }
  return !obj;
}

function arrayToString(arr, delimiter) {
  if (!arr) return '';
  return arr.join(delimiter);
}

function stringToArray(str, delimiter) {
  if (!str) return [];
  return str.split(delimiter);
}
