import React, { useEffect, useState } from 'react';
import { Delete, Edit } from '@mui/icons-material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import config from '../config';
import CustomTable from '../shared/components/CustomTable/CustomTable';

import ObjectUtils from '../shared/utils/object.utils';
import Modal from '../shared/components/Modal';
import Priority from './Priority.class';
import LoggingService from '../shared/Logging.api';
import PriorityDeleteForm from './forms/PriorityDeleteForm';
import PriorityUpdateForm from './forms/PriorityUpdateForm';

export default function PrioritiesTable({
  priorities,
  onPrioritiesUpdated, // two-way binding
  filter,
  hideFuturePriorities,
}) {
  const { DEFAULT_LANGUAGE } = config;
  const { flattenToStr } = ObjectUtils;
  const [priorityList, setPriorityList] = useState(priorities);

  const [isEndNowOpen, setEndNowOpen] = useState(false);
  const [priorityToEnd, setPriorityToEnd] = useState(null);

  const [isEditOpen, setEditOpen] = useState(false);
  const [priorityToEdit, setPriorityToEdit] = useState(null);

  const tableColumns = [
    {
      id: 'title',
      name: 'Title',
      path: `languages.${DEFAULT_LANGUAGE}.title`,
      sort: (priority) => priority.languages[DEFAULT_LANGUAGE].title,
    },
    {
      id: 'body',
      name: 'Body',
      path: `languages.${DEFAULT_LANGUAGE}.body`,
      sort: (priority) => priority.languages[DEFAULT_LANGUAGE].body,
    },
    {
      id: 'cta',
      name: 'CTA Links',
      path: `languages.${DEFAULT_LANGUAGE}.callsToAction`,
      display: (ctas) => {
        return ctas.map((cta) => (
          <div key={cta.id}>
            {cta.copy} (<code>{cta.link}</code>)
          </div>
        ));
      },
      filter: (priority, search) => {
        const values = flattenToStr(
          _.map(priority.languages[DEFAULT_LANGUAGE].callsToAction, (cta) => {
            return `${cta.copy} ${cta.link}`;
          })
        );
        return values.toLowerCase().includes(search.toLowerCase());
      },
      sort: (priority) =>
        _.get(priority, `languages[${DEFAULT_LANGUAGE}].callsToAction[0].copy`),
    },
    {
      id: 'howToComplete',
      name: 'How to Complete',
      path: `languages.${DEFAULT_LANGUAGE}.getStartedSteps`,
      display: (steps) => {
        return (
          <ol>{steps.map((step) => step && <li key={step}>{step}</li>)}</ol>
        );
      },
      filter: (priority, search) => {
        const values = flattenToStr(
          priority.languages[DEFAULT_LANGUAGE].getStartedSteps
        );
        return values.toLowerCase().includes(search.toLowerCase());
      },
      sort: (priority) =>
        priority.languages[DEFAULT_LANGUAGE].getStartedSteps[0],
    },
    {
      id: 'startDate',
      name: 'Start Date',
      isDate: true,
      path: 'startDate',
    },
    {
      id: 'endDate',
      name: 'End Date',
      isDate: true,
      path: 'endDate',
      display: (endDate) => {
        return _.isEmpty(endDate) ? 'Evergreen' : endDate;
      },
    },
    {
      id: 'lang',
      name: 'Languages',
      path: 'availableLanguages',
    },
  ];
  const tableActions = [
    {
      id: 'edit',
      name: 'Edit',
      icon: <Edit />,
      textColor: 'primary',
      onClick: (priority) => handleEditPriority(priority),
    },
    {
      id: 'endNow',
      name: 'End Now',
      icon: <Delete />,
      textColor: 'error',
      onClick: (priority) => handleDeletePriority(priority),
    },
  ];

  function handleDeletePriority(priority) {
    setPriorityToEnd(priority);
    setEndNowOpen(true);
  }

  function handleEditPriority(priority) {
    setPriorityToEdit(priority);
    setEditOpen(true);
  }

  function onPriorityUpdated(updatedPriority) {
    const idToUpdate = updatedPriority.id;
    const searchablePriority = Priority.attachSearchTerm(updatedPriority);

    setPriorityList(
      priorityList.map((priority) =>
        priority.id === idToUpdate ? searchablePriority : priority
      )
    );
    setEditOpen(false);
    setPriorityToEdit(null);
  }

  function deletePriority(response, id) {
    if (response.status === 204) {
      setPriorityList(priorityList.filter((priority) => priority.id !== id));
    }
    setEndNowOpen(false);
    setPriorityToEnd(null);
  }

  // two-way binding (don't worry - they don't recurse)
  useEffect(() => {
    setPriorityList(priorities);
  }, [priorities]);

  useEffect(() => {
    onPrioritiesUpdated(priorityList);
  }, [priorityList]);

  return (
    <>
      <CustomTable
        data={priorityList}
        columns={tableColumns}
        actions={tableActions}
        omniSearchValue={filter}
        hideFuturePriorities={hideFuturePriorities}
      />
      {priorityToEnd !== null && (
        <Modal
          title="End Priority"
          isOpen={isEndNowOpen}
          setOpen={setEndNowOpen}
        >
          <PriorityDeleteForm
            priority={priorityToEnd}
            onAPISuccess={(res) => deletePriority(res, priorityToEnd.id)}
            onAPIError={(err) =>
              LoggingService.error('Error deleting priority', err)
            }
            spacing={2}
          />
        </Modal>
      )}

      <Modal title="Edit Priority" isOpen={isEditOpen} setOpen={setEditOpen}>
        <PriorityUpdateForm
          initialPriority={priorityToEdit}
          // eslint-disable-next-line react/jsx-no-bind
          onAPISuccess={onPriorityUpdated}
          onAPIError={(err) =>
            LoggingService.error('Error updating priority', err)
          }
          spacing={2}
        />
      </Modal>
    </>
  );
}

PrioritiesTable.propTypes = {
  priorities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onPrioritiesUpdated: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  hideFuturePriorities: PropTypes.bool.isRequired,
};
