import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ConfirmProvider } from 'material-ui-confirm';
import { AuthProvider } from 'react-oauth2-code-pkce';
import config from './config';
import theme from './theme';
import Layout from './navigation/Layout.page';
import AlertsProvider from './shared/alerts/Alerts.context';

export default function App() {
  const { REDIRECT_URL, PINGFED_BASE } = config;

  const authConfig = {
    clientId: 'MyLocalSocialAdminPKCE',
    authorizationEndpoint: `${PINGFED_BASE}/as/authorization.oauth2`,
    tokenEndpoint: `${PINGFED_BASE}/as/token.oauth2`,
    redirectUri: REDIRECT_URL,
    scope: 'openid full',
    logoutEndpoint: `${PINGFED_BASE}/idp/startSLO.ping`,
    autoLogin: false,
  };

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <BrowserRouter>
          <CssBaseline />
          <AlertsProvider>
            <AuthProvider authConfig={authConfig}>
              <Layout />
            </AuthProvider>
          </AlertsProvider>
        </BrowserRouter>
      </ConfirmProvider>
    </ThemeProvider>
  );
}
