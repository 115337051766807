import _ from 'lodash';

export default {
  hasDefaultStore,
  extractStoreNumberFromEmail,
  extractDataFromAuthorization,
  generateAuthorizationString,
};

// Check if the return of extractStoreNumberFromEmail is an empty string
function hasDefaultStore(email) {
  if (extractStoreNumberFromEmail(email).length > 0) {
    return true;
  }
  return false;
}

function extractStoreNumberFromEmail(email) {
  const regex = /\.(.*?)\./g;
  const matches = [];
  let match;
  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(email)) !== null) {
    const digits = match[1].match(/\d+/g);
    if (digits) {
      matches.push(digits.join(''));
    }
  }
  // the 'join' to convert the array to a string and the 'replace' to remove leading zeros
  return matches.join('').replace(/^0+/, '');
}

function extractDataFromAuthorization(authorization, key) {
  let store;
  const stores = [];
  const patron = new RegExp(`\\b${key}=(\\d+)`, 'g');
  // eslint-disable-next-line no-cond-assign
  while ((store = patron.exec(authorization))) {
    stores.push(store[1]);
  }
  return stores.join(',');
}

// create the authorization string from the values passed in the textfields and the same index in the authAttributes array
function generateAuthorizationString(authAttributes, textFields) {
  let authorizationString = '';
  textFields.forEach((textField, index) => {
    if (textField?.trim().length > 0) {
      // if authAttributes[index].key is store, then we need to add one store= per store number separated by a comma
      const { key } = authAttributes[index];
      if (key === 'store') {
        textField
          .split(',')
          .map((store) => store.trim())
          .filter((store) => !_.isEmpty(store))
          .forEach((store) => {
            authorizationString += `store=${store},`;
          });
      } else {
        authorizationString += `${key}=${textField.trim()},`;
      }
    }
  });
  return authorizationString;
}
