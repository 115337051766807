/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Divider,
} from '@mui/material';
import { createStyles } from '@mui/styles';

import _ from 'lodash';

import config from '../../config';
import Priority from '../Priority.class';
import PrioritiesService from '../Priorities.api';
import PriorityLanguageCopy from '../PriorityLanguageCopy.class';
import FormValidationsUtils from '../../shared/utils/formValidations.utils';
import PriorityLanguagesForm from './PriorityLanguagesForm';
import PriorityTargetingForm from './PriorityTargetingForm';
import PriorityLifetimeForm from './PriorityLifetimeForm';

const { ALL_LANGUAGES } = config;
const { required, childValidation } = FormValidationsUtils;

export default function PriorityUpdateForm({
  onAPISuccess,
  onAPIError,
  spacing,
  initialPriority,
}) {
  // state
  const priorityId = initialPriority ? initialPriority.id : null;
  const [priority, setPriority] = useState(initialPriority || new Priority());
  const [keyword, setKeyword] = useState(initialPriority?.keyword || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState(null);

  // validation
  const [isValidating] = useState(true);
  const [validationErrors, setValidationErrors] = useState({}); // top-level only
  const [areChildrenValid, setAreChildrenValid] = useState({
    // this is sort of duplicated logic - consider triggering each form's validate() on load
    languages: _.some(initialPriority?.languages),
    targeting: true,
    lifetime: _.some(initialPriority?.lifetime),
  });
  const styles = createStyles({
    divider: {
      margin: '20px 0',
    },
  });

  function validate() {
    const newErrors = {};

    if (isValidating) {
      if (!required().isValid(keyword)) {
        newErrors.keyword = required().helperText;
      }

      const hasErrors = !_.isEmpty(newErrors);
      if (hasErrors || !childValidation().isValid(areChildrenValid)) {
        newErrors.summary = childValidation().helperText;
      }
    }

    setValidationErrors(newErrors);
    return _.isEmpty(newErrors);
  }
  useEffect(() => {
    validate();
  }, [keyword, priority]);

  function updateChild(key, newData, isValid) {
    setPriority({ ...priority, [key]: newData });
    setAreChildrenValid({ ...areChildrenValid, [key]: isValid });
  }

  // saving
  function sanitizeAndFormatCurrentState() {
    const nonEmptyLanguages = PriorityLanguageCopy.filterEmptyLanguages(
      priority.languages
    );

    const criteriaToTrim = ['states', 'markets', 'regions', 'storeNumbers'];
    const trimmedTargeting = {
      ...priority.targeting,
      ...criteriaToTrim.reduce(
        (acc, key) =>
          priority.targeting[key]
            ? {
                ...acc,
                [key]: priority.targeting[key].map((str) => str.trim()),
              }
            : acc,
        {}
      ),
    };

    const keywordNoTrailingComma = keyword.replace(/,+$/, '');

    return new Priority(
      priorityId,
      nonEmptyLanguages,
      trimmedTargeting,
      priority.lifetime,
      keywordNoTrailingComma
    );
  }

  function saveToAPI() {
    setIsSubmitting(true);
    setApiError(null);

    const finalPriority = sanitizeAndFormatCurrentState();
    PrioritiesService.updateOrCreatePriority(finalPriority)
      .then(onAPISuccess)
      .catch((err) => {
        setApiError(err);
        setIsSubmitting(false);

        onAPIError(err);
      });
  }

  return (
    <Box>
      <Divider sx={styles.divider} />
      <Grid container id="languageSpecific" spacing={spacing}>
        <PriorityLanguagesForm
          initialLanguages={initialPriority?.languages}
          allowedLanguages={ALL_LANGUAGES}
          onStateChanged={(newState, isValid) =>
            updateChild('languages', newState, isValid)
          }
          spacing={spacing}
          isValidating={isValidating}
        />
      </Grid>
      <Divider sx={styles.divider} />
      <Grid container id="targeting" spacing={spacing}>
        <PriorityTargetingForm
          initialTargeting={initialPriority?.targeting}
          onStateChanged={(newState, isValid) =>
            updateChild('targeting', newState, isValid)
          }
          spacing={spacing}
          isValidating={isValidating}
        />
      </Grid>
      <Divider sx={styles.divider} />
      <Grid container id="keywords" spacing={spacing}>
        <Grid item xs={12}>
          <Typography variant="h5">Keywords</Typography>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Keywords (comma separated)"
            fullWidth
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            error={_.has(validationErrors, 'keyword')}
            helperText={validationErrors.keyword}
          />
        </Grid>
      </Grid>
      <Divider sx={styles.divider} />
      <Grid container id="lifetime" spacing={spacing}>
        <PriorityLifetimeForm
          initialLifetime={{
            startDate: initialPriority?.startDate,
            endDate: initialPriority?.endDate,
          }}
          onStateChanged={(newState, isValid) =>
            updateChild('lifetime', newState, isValid)
          }
          spacing={spacing}
          isValidating={isValidating}
        />
      </Grid>
      <Divider sx={styles.divider} />
      <Grid container id="actions" spacing={spacing}>
        {_.has(validationErrors, 'summary') && (
          <Grid item xs={12}>
            <Typography color="error">{validationErrors.summary}</Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            type="button"
            variant="contained"
            fullWidth
            color="primary"
            size="large"
            onClick={() => saveToAPI()}
            disabled={isSubmitting || !_.isEmpty(validationErrors)}
          >
            Confirm & {initialPriority ? 'Update' : 'Create'}
          </Button>
        </Grid>

        {apiError && (
          <Grid item xs={12}>
            <Typography color="error">
              An error occurred during submission: &quot;{apiError.message}
              &quot; {apiError.status && `(status ${apiError.status})`}
            </Typography>
            <Typography color="error">
              Please try again. If the issue persists, please contact MLS
              support.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

PriorityUpdateForm.propTypes = {
  initialPriority: PropTypes.instanceOf(Priority),
  onAPISuccess: PropTypes.func,
  onAPIError: PropTypes.func,
  spacing: PropTypes.number,
};

PriorityUpdateForm.defaultProps = {
  initialPriority: null,
  onAPISuccess: () => {},
  onAPIError: () => {},
  spacing: 1,
};
