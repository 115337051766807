import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import {
  Typography,
  Modal,
  Box,
  TextField,
  Button,
  IconButton,
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  CircularProgress,
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import _ from 'lodash';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useUser } from '../auth/User.context';
import Sticker from './Sticker';
import UploadButton from '../shared/components/UploadButton';
import styleColors from '../colors.styles';
import StickersCreateForm from './StickersCreateForm';
import StickersService from './Stickers.service';
import LoggingService from '../shared/Logging.api';
import { AlertsContext } from '../shared/alerts/Alerts.context';

const StickerCreateModal = ({ categories, closeModal, refetchStickers }) => {
  // keeping track of new stickers, category, start and end date submitted in the form
  const [uploadedStickers, setUploadedStickers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(null);

  // errors and warnings
  const [error, setError] = useState([]);
  const [invalidStickerNames, setInvalidStickerNames] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const { showAlert } = useContext(AlertsContext);

  const {
    state: { displayName },
  } = useUser();

  useEffect(() => {
    setStartDate(moment());
  }, []);

  // validate sticker names when they are uploaded or changed
  useEffect(() => {
    const invalidNames = StickersService.validateStickerNames(uploadedStickers);
    if (invalidNames) {
      setInvalidStickerNames(invalidNames);
    }
  }, [uploadedStickers]);

  useEffect(() => {
    // if there are currently errors, re-validate to see if any have cleared
    if (error.length > 0) {
      const errors = StickersService.validateSubmit(
        uploadedStickers,
        selectedCategory,
        startDate,
        endDate
      );
      setError(errors);
    }
  }, [selectedCategory, startDate, endDate, uploadedStickers]);

  const styles = {
    box: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'white',
      width: '800px',
      border: '2px solid #000',
      boxShadow: 24,
      p: 4,
      overflow: 'scroll',
      maxHeight: '90vh',
    },
    image: {
      width: '100%',
      height: '100%',
      maxHeight: '110px',
      minHeight: '70px',
      minWidth: '70px',
      maxWidth: '110px',
      objectFit: 'contain',
      margin: ' 0 auto',
    },
    previewSticker: {
      width: '100%',
      height: '100%',
      maxHeight: '110px',
      minHeight: '70px',
      minWidth: '70px',
      maxWidth: '110px',
      objectFit: 'contain',
      backgroundColor: 'black',
      padding: '5px',
      borderRadius: '5px',
      margin: '0 auto',
      position: 'relative',
    },
    cancelButtonWrapper: {
      position: 'absolute',
      top: 0,
      zIndex: 100,
      right: 0,
      color: 'white',
    },
    closeModalButton: {
      position: 'absolute',
      top: 20,
      zIndex: 100,
      right: 20,
    },
    error: {
      padding: '10px',
      backgroundColor: styleColors.base.error,
      borderRadius: '5px',
      margin: '20px',
      height: '20%',
    },
    stickerGrid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      backgroundColor: styleColors.base.almost_white_blue,
      padding: '10px',
      margin: '0 auto 20px auto',
      width: '100%',
    },
    helpText: {
      margin: '20px 0',
      gridColumnStart: 1,
      gridColumnEnd: 4,
      textAlign: 'center',
    },
    uploadButton: {
      gridColumnStart: 1,
      gridColumnEnd: 4,
      marginBottom: '10px',
    },
  };

  function handleNameChange(event, sticker) {
    const updatedSticker = {
      ...sticker,
      name: event.target.value,
    };

    const updatedArray = _.filter(
      uploadedStickers,
      (s) => s.image !== sticker.image
    );
    setUploadedStickers(orderStickers([...updatedArray, updatedSticker]));
  }

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const formattedStickers = [];

    // final validation
    const errors = StickersService.validateSubmit(
      uploadedStickers,
      selectedCategory,
      startDate,
      endDate
    );
    if (errors.length > 0) {
      setError(errors);
      setSubmitLoading(false);
      return;
    }

    // Format stickers for upload

    // set end date to null if 'now' is selected (evergreen)
    // add 1 day to end date to make it inclusive of the end of the selected day
    const endDateValue =
      endDate === 'now'
        ? null
        : StickersService.getStartEndTime(endDate, 'end');

    const category_id = _.find(categories, { name: selectedCategory })?.id;

    _.forEach(uploadedStickers, (sticker) => {
      formattedStickers.push({
        name: _.trim(sticker.name),
        start_date: StickersService.getStartEndTime(startDate, 'start'),
        end_date: endDateValue,
        category: category_id,
        image: sticker.image,
        created_by: _.trim(displayName).toLowerCase(),
      });
    });

    //  now that things are formatted, submit the stickers
    let response;
    try {
      response = await StickersService.createStickers(formattedStickers);
    } catch (err) {
      LoggingService.error('Error creating stickers', {
        code: err.code,
        message: err.message,
        response: err.response,
      });
      showAlert('Error creating stickers', 'error');
      setSubmitLoading(false);
      return;
    }

    if (response.length > 0) {
      showAlert(
        `${response.length} sticker(s) created successfully`,
        'success'
      );
    }

    setSubmitLoading(false);
    // refetch stickers at the parent level to show them in the list
    refetchStickers();
    closeModal();
  };

  const handleInputChange = (name, value) => {
    // no validation here, because it happens in the useEffect when these things change
    if (name === 'startDate') {
      setStartDate(value);
    } else if (name === 'endDate') {
      setEndDate(value);
    } else if (name === 'category') {
      setSelectedCategory(value);
    }
  };

  const handleFileUpload = async (event) => {
    setLoading(true);
    const files = [];

    try {
      await Promise.all(
        Array.from(event.target.files).map(async (file, index) => {
          const simpleName = _.replace(file.name, /\.(jpg|png)$/i, '');
          const fileUrl = await StickersService.uploadStickerFile(file);

          files.push({
            name: simpleName,
            image: fileUrl,
            upload_time: Date.now() + index,
          });
        })
      );
    } catch (err) {
      LoggingService.error('Error uploading sticker', {
        code: err.code,
        message: err.message,
        response: err.response,
      });
      showAlert('Error uploading sticker file', 'error');
      setLoading(false);
      return;
    }

    if (files.length >= 1) {
      setUploadedStickers([...uploadedStickers, ...files]);
    }
    setLoading(false);
  };

  // remove the sticker from the create flow
  function removeSticker(sticker) {
    const updatedArray = _.filter(
      uploadedStickers,
      (s) => s.image !== sticker.image
    );
    setUploadedStickers(updatedArray);
  }

  function orderStickers(stickerList) {
    return _.sortBy(stickerList, ['upload_time']);
  }

  /* Render functions - broken out to keep the return readable */

  function renderSticker(sticker) {
    return (
      <Grid item>
        <div style={{ position: 'relative', height: '60%' }}>
          <div style={styles.previewSticker}>
            <Sticker sticker={sticker} />
            <IconButton
              style={styles.cancelButtonWrapper}
              onClick={() => removeSticker(sticker)}
            >
              <CancelIcon style={{ width: '20px' }} />
            </IconButton>
          </div>
        </div>
        <TextField
          label="Sticker name"
          variant="filled"
          margin="dense"
          value={sticker.name}
          onChange={(event) => handleNameChange(event, sticker)}
          style={
            invalidStickerNames.includes(sticker.name)
              ? { backgroundColor: styleColors.base.error }
              : {}
          }
          fullWidth
          required
        />
      </Grid>
    );
  }

  const renderDialog = () => {
    return (
      <Dialog open={dialogOpen}>
        <DialogTitle>
          By closing this modal, you will lose your sticker progress.{' '}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Stay</Button>
          <Button onClick={() => closeModal()}>Leave</Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {renderDialog()}
      <Modal open>
        <>
          <Box sx={styles.box}>
            <CancelIcon
              onClick={() => setDialogOpen(true)}
              style={styles.closeModalButton}
            />
            <Typography variant="h4" style={{ marginBottom: '30px' }}>
              Create sticker(s)
            </Typography>
            <Grid container style={styles.stickerGrid} spacing={4}>
              {loading && uploadedStickers.length === 0 ? (
                <CircularProgress
                  style={{ margin: '15px auto', gridColumn: '1 / 4' }}
                />
              ) : (
                orderStickers(uploadedStickers).map((sticker) =>
                  renderSticker(sticker)
                )
              )}
              <Typography variant="body2" style={styles.helpText}>
                {uploadedStickers.length === 0 ? (
                  `Upload one or more images(.png) to get started.`
                ) : (
                  <>
                    <ErrorOutlineIcon style={{ width: '15px' }} /> This name is
                    used for organizational and reporting purposes, and is not
                    shown to the MLS user.
                  </>
                )}
              </Typography>
              <UploadButton
                style={styles.uploadButton}
                handleFileUpload={handleFileUpload}
                text={
                  uploadedStickers.length === 0
                    ? 'Upload file(s)'
                    : 'Upload more'
                }
                accept=".png"
              />
            </Grid>
            <StickersCreateForm
              categories={categories}
              handleInputChange={handleInputChange}
              handleSubmit={handleSubmit}
              selectedCategory={selectedCategory}
              error={error[0] || ''}
              submitLoading={submitLoading}
            />
            <Typography
              style={{
                ...styles.error,
                visibility: error.length > 0 ? '' : 'hidden',
              }}
            >
              {error[0]}
            </Typography>
          </Box>
        </>
      </Modal>
    </LocalizationProvider>
  );
};

export default StickerCreateModal;

StickerCreateModal.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  closeModal: PropTypes.func.isRequired,
  refetchStickers: PropTypes.func.isRequired,
};
