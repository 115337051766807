/**
 * PLEASE NOTE: Mock data is being used to make components display for testing
 * Will be finalized once backend is complete
 * Some comments & TODOs can also be cleared out once the rest of the POTD work is complete
 */
import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import moment from 'moment-timezone';
import POTDContainer from './POTDContainer';
import POTDWeek from './POTDListWeek';
import config from '../config';
import SpinningSpark from '../shared/components/SpinningSpark';
import POTDListService from './POTDList.service';
import POTDApi from './POTD.api';
import LoggingService from '../shared/Logging.api';
import { AlertsContext } from '../shared/alerts/Alerts.context';

const { getWalmartWeek } = config;
const { formatPotdList, fillInBlankCards, assignStatus } = POTDListService;

// TODO: once POTD Create is implemented, review this file for logic relating to start time.
// Depending on the time set on create and timezone used, the logic may need adjusted

// POTDContainer takes POTDWeek components as children, which takes an array of POTDCard components as a prop
export default function POTD() {
  const [potdList, setPotdList] = useState([]);
  const [formattedPotdList, setFormattedPotdList] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [errorOccurred, setErrorOccurred] = useState(false);
  const { showAlert } = useContext(AlertsContext);

  const walmartWeeks = {
    previous_week: getWalmartWeek(moment().subtract(7, 'days').calendar()),
    current_week: getWalmartWeek(moment()),
    next_week: getWalmartWeek(moment().add(7, 'days').calendar()),
  };

  useEffect(() => {
    fetchPotdList();
  }, []);

  const fetchPotdList = async () => {
    setIsLoading(true);
    return POTDApi.getPotdList()
      .then((response) => {
        setPotdList(response.data || []);
        setErrorOccurred(false);
      })
      .catch((error) => {
        LoggingService.error('Error getting Potd List', error);
        setPotdList([]);
        setErrorOccurred(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deletePotd = async (potdId) => {
    setIsLoading(true);
    return POTDApi.deletePotd(potdId)
      .then(() => {
        showAlert('PotD was successfully deleted', 'success');
      })
      .catch((error) => {
        LoggingService.error('Error deleting Potd', error);
        showAlert('Failed to delete PotD', 'error');
      })
      .finally(() => {
        fetchPotdList();
      });
  };

  useEffect(() => {
    let formattedPotds = formatPotdList(potdList);
    // add in blank cards
    formattedPotds = fillInBlankCards(formattedPotds);

    // add status
    formattedPotds = assignStatus(formattedPotds);

    // set formattedPotdList state
    setFormattedPotdList(formattedPotds);
  }, [potdList]);

  return (
    <>
      <POTDContainer showAddPotdButton>
        <Grid className="POTD-week-container">
          {isLoading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
              sx={{ height: '80vh' }}
            >
              <SpinningSpark isSpinning />
            </Box>
          ) : (
            <Grid flexGrow>
              {errorOccurred ? (
                <Typography textAlign="center">
                  There was an error getting the POTD list.
                </Typography>
              ) : (
                <>
                  <POTDWeek
                    week={walmartWeeks.previous_week}
                    potdList={_.filter(formattedPotdList, [
                      'walmart_week',
                      walmartWeeks.previous_week,
                    ])}
                  />
                  <POTDWeek
                    week={walmartWeeks.current_week}
                    potdList={_.filter(formattedPotdList, [
                      'walmart_week',
                      walmartWeeks.current_week,
                    ])}
                    deletePotd={deletePotd}
                  />
                  <POTDWeek
                    week={walmartWeeks.next_week}
                    potdList={_.filter(formattedPotdList, [
                      'walmart_week',
                      walmartWeeks.next_week,
                    ])}
                    deletePotd={deletePotd}
                  />
                </>
              )}
            </Grid>
          )}
        </Grid>
      </POTDContainer>
    </>
  );
}
