import _ from 'lodash';

export default {
  flattenToStr,
  toCamelCaseKeys,
  toSnakeCaseKeys,
  isObjectBlank,
};

function flattenToStr(obj) {
  if (obj === null || obj === undefined) return '';
  if (typeof obj === 'string') return obj.trim();

  // recurse on objects and arrays
  const innerValues = Array.isArray(obj) ? obj : Object.values(obj);
  return innerValues.length !== 0
    ? innerValues.map(flattenToStr).join(' ')
    : '';
}

function toCamelCaseKeys(obj) {
  return _.mapKeys(obj, (value, key) => _.camelCase(key));
}

function toSnakeCaseKeys(obj) {
  return _.mapKeys(obj, (value, key) => _.snakeCase(key));
}

/*
  "empty" !== "blank" - e.g. {a: '', b: ''} is blank, but not empty
  This function does not recurse into nested objects or arrays.
*/
function isObjectBlank(obj) {
  return _.isEmpty(obj) || _.every(obj, _.isEmpty);
}
