import AppApi from '../shared/App.api';

export default { getPowerBICredentials };

function getPowerBICredentials() {
  return AppApi.get('/admin/powerbi-credentials', {
    params: {
      report: 'proactiveMusicMatch',
    },
  });
}
