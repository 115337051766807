import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Grid, Paper, Typography, IconButton } from '@mui/material';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import ReplyIcon from '@mui/icons-material/Reply';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { getReadableNum } from '../shared/utils/number.utils';
import styleColors from '../colors.styles';

const styles = {
  candidateCard: {
    borderRadius: 15,
    height: 220,
    width: '100%',
    cursor: 'pointer',
  },
  message: {
    fontSize: '.9rem',
  },
  store: {
    textAlign: 'center',
  },
  metric: {
    backgroundColor: `${styleColors.base.walmart_blue}2b`,
    borderRadius: 5,
    paddingLeft: 8,
    paddingRight: 5,
    paddingTop: 5,
    alignContent: 'center',
    display: 'flex',
  },
  metricIcon: {
    marginRight: 5,
  },
  metricValue: {
    fontSize: '.85rem',
  },
  linkButton: {
    padding: 0,
  },
  thumbnailContainer: {
    height: 150,
    marginBottom: 5,
    position: 'relative',
  },
  thumbnail: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  videoOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: '90%',
  },
  playIcon: {
    color: styleColors.base.light,
  },
};

export default function POTDCandidate({ data }) {
  const { state } = useLocation();
  const navigate = useNavigate();

  const insights = {
    reach: {
      rawValue: data.insights.reach,
      value: getReadableNum(data.insights.reach, 2, 3),
      icon: <PeopleOutlineIcon fontSize="small" />,
      cols: 3,
    },
    reactions: {
      rawValue: data.insights.reactions,
      value: getReadableNum(data.insights.reactions, 2, 3),
      icon: <ThumbUpOutlinedIcon fontSize="small" />,
      cols: 2,
    },
    comments: {
      rawValue: data.insights.comments,
      value: getReadableNum(data.insights.comments, 2, 3),
      icon: <QuestionAnswerOutlinedIcon fontSize="small" />,
      cols: 2,
    },
    shares: {
      rawValue: data.insights.shares,
      value: getReadableNum(data.insights.shares, 2, 3),
      icon: <ReplyIcon fontSize="small" />,
      cols: 2,
    },
  };

  return (
    <Grid item xs={12} lg={6} className="POTD-candidate">
      <Paper
        style={styles.candidateCard}
        onClick={() => {
          const potdKeys = [
            'page_id',
            'page_name',
            'message',
            'publish_time',
            'type',
          ];
          const potd = {
            ..._.pick(data, potdKeys),
            facebook_id: data.post_id,
            store: data.store_number,
            start_date: state?.start_date,
          };

          navigate('/potd/schedule', {
            state: potd,
          });
        }}
      >
        <Grid container sx={{ p: 2, height: '100%' }}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={4} sx={{ justifyContent: 'space-between' }}>
              <Grid item xs>
                <div style={styles.thumbnailContainer}>
                  <img
                    src={data.type === 'video' ? data.picture : data.media}
                    alt={`Media for post ${data.post_id}`}
                    style={styles.thumbnail}
                  />
                  {data.type === 'video' && (
                    <div style={styles.videoOverlay}>
                      <PlayArrowIcon
                        sx={{ fontSize: 120 }}
                        color={styleColors.base.light}
                        style={styles.playIcon}
                      />
                    </div>
                  )}
                </div>
              </Grid>
              <Grid item xs style={styles.store}>
                Store #{data.store_number}
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={8}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Grid item xs={12} sx={{ alignSelf: 'flex-start' }}>
                <Typography variant="p" style={styles.message}>
                  {data.message}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sx={{
                  mt: 'auto',
                  alignItems: 'end',
                  justifyContent: 'space-between',
                }}
              >
                {_.map(insights, (i, k) => (
                  <div
                    key={k}
                    style={styles.metric}
                    title={`${k}: ${i.rawValue}`}
                  >
                    <div style={styles.metricIcon}>{i.icon}</div>
                    <div style={styles.metricValue}>{i.value}</div>
                  </div>
                ))}
                <div>
                  <IconButton
                    href={`https://www.facebook.com/${data.post_id}`}
                    style={styles.linkButton}
                  >
                    <FacebookRoundedIcon
                      style={{ color: styleColors.base.walmart_blue }}
                      fontSize="large"
                    />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

POTDCandidate.propTypes = {
  data: PropTypes.shape({
    insights: PropTypes.shape({
      reach: PropTypes.number.isRequired,
      reactions: PropTypes.number.isRequired,
      comments: PropTypes.number.isRequired,
      shares: PropTypes.number.isRequired,
    }),
    message: PropTypes.string.isRequired,
    media: PropTypes.string.isRequired,
    post_id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    picture: PropTypes.string,
    store_number: PropTypes.number.isRequired,
  }).isRequired,
};
