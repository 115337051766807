import moment from 'moment-timezone';
import _ from 'lodash';

/**
 * Function broken out only for unit testing purposes
 * @param {string} timezone - user's timezone e.g. 'US/Eastern'
 * @returns current date and time in the user's timezone
 */
export const getNowinTimeZone = (timezone) => {
  return moment().tz(timezone).format('YYYY-MM-DD HH:mm');
};

/**
 * Function to format posts to user's timezone and sort by publish time
 * sorts so that the closest to publishing time is on top for unreviewed page
 * @param {Array} postList - list of posts
 * @param {string} timezone - user's timezone e.g. 'US/Eastern'
 * @param {string} sortOrder - direction to order the list by publish time 'asc' or 'desc'
 * @returns sorted array with formatted publish time in user's timezone
 */
export const formatToUserTimezoneAndSortPosts = (
  postList,
  timezone,
  sortOrder
) => {
  const formattedPosts = postList.map((post) => {
    // add a formatted publish time in the admin users's timezone
    return {
      ...post,
      tz_publish_time: moment
        .tz(post.publish_time, timezone)
        .format('YYYY-MM-DD HH:mm'),
    };
  });
  // sort by publish time based on sortOrder which should be 'desc' or 'asc'
  return _.orderBy(formattedPosts, 'tz_publish_time', [sortOrder]);
};

/**
 * Filters posts to only show posts whose publish_time is after now
 * unifies dates to user's timezone for display
 * sorts posts by publish time so closest to publish_time is on top
 * @param {Array} postList  - array of post objects
 * @param {*} timezone
 * @param {*} getNow - function to get current date and time in the user's timezone
 *                - used for unit testing; default is getNowinTimeZone, but unit test passes mock function
 * @returns
 */
export const filterPosts = (postList, timezone, listType) => {
  const formattedPosts = formatToUserTimezoneAndSortPosts(
    postList,
    timezone,
    listType === 'unreviewed' ? 'desc' : 'asc'
  );

  const [unreviewed, reviewed] = _.partition(formattedPosts, (post) => {
    return post.reviewAction === null || post.reviewAction === undefined;
  });

  return listType === 'unreviewed'
    ? _.orderBy(
        unreviewed,
        [
          (post) => {
            const sortItem = _.find(_.get(post, 'audio_detected', []), {
              allowedMusic: false,
            });
            return _.get(sortItem, 'confidence', -Infinity);
          },
        ],
        ['desc']
      )
    : reviewed;
};

export const getPostStatus = (post) => {
  if (post.reviewAction) {
    return post.reviewAction.action;
  }
  return 'pending';
};

export const formattedFirstname = (firstname, lastname = '') => {
  if (!firstname) return null;
  const splittedFirstname = firstname.split(' ');
  const remainingParts = splittedFirstname.slice(1, splittedFirstname.length);
  return `${splittedFirstname[0].replace(
    /^(\w).*/,
    '$1.'
  )} ${remainingParts.join(' ')} ${lastname}`;
};
