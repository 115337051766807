import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { IconButton, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useConfirm } from 'material-ui-confirm';
import styleColors from '../colors.styles';

// statuses that enable edit and delete actions
const ACTIONABLE_STATUS = ['active', 'scheduled'];

const styles = {
  cardContainer: {
    borderRadius: '16px',
    opacity: 1,
    maxWidth: '12vw',
    border: '0px solid transparent',
    transition: 'border 0.5s ease-in-out',
  },
  cardDate: {
    textAlign: 'center',
    flexShrink: 1,
    borderRadius: '16px 16px 0 0',
    padding: '1vh 10px',
    fontSize: '1rem',
    marginTop: 0,
  },
  cardContentContainer: {
    padding: '.5vw',
    fontSize: '.85em',
  },
  thumbnailContainer: {
    width: '90%',
    height: '12vh',
    margin: '0 auto',
    position: 'relative',
  },
  thumbnail: {
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  videoOverlay: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: '90%',
  },
  playIcon: {
    color: styleColors.base.light,
  },
  cardFooterGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 1fr',
  },
  noPotdGrid: {
    display: 'grid',
    gridTemplateRows: '3fr 1.25fr',
  },
  storeNumber: {
    textAlign: 'center',
    color: styleColors.text.gray,
    margin: '2px 10px',
    fontSize: '1em',
  },
  iconButton: {
    backgroundColor: styleColors.base.walmart_blue,
    height: '1.75vw',
    width: '1.75vw',
    minWidth: '25px',
    minHeight: '25px',
    display: 'flex',
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: styleColors.base.light,
    },
  },
  icons: {
    color: styleColors.base.light,
    maxWidth: '1.25vw',
    minWidth: '15px',
    '&:hover': {
      color: styleColors.base.walmart_blue,
    },
  },
  addButton: {
    backgroundColor: styleColors.base.spark_yellow,
    color: styleColors.text.body,
    display: 'block',
    margin: '0 auto',
    '&:hover': {
      backgroundColor: styleColors.base.very_light_blue,
    },
  },
  inactive: {
    cardContainer: {
      backgroundColor: styleColors.base.inactive,
    },
    cardDate: {
      backgroundColor: styleColors.base.light_gray,
      color: styleColors.text.body,
    },
  },
  active: {
    cardContainer: {
      backgroundColor: styleColors.base.very_light_blue,
      border: `3px solid ${styleColors.base.walmart_blue}`,
    },
    cardDate: {
      backgroundColor: styleColors.base.walmart_blue,
      color: styleColors.base.light,
      borderRadius: '12px 12px 0 0',
    },
  },
  scheduled: {
    cardContainer: {
      backgroundColor: styleColors.base.almost_white_blue,
    },
    cardDate: {
      backgroundColor: styleColors.base.very_light_blue,
      color: styleColors.text.body,
    },
  },
  noPotd: {
    cardContainer: {
      backgroundColor: styleColors.base.light_yellow,
    },
    cardDate: {
      backgroundColor: styleColors.base.spark_yellow,
      color: styleColors.text.body,
    },
    text: {
      fontSize: '1.25em',
      textAlign: 'center',
    },
    helpText: {
      color: styleColors.text.gray,
      fontSize: '.75em',
    },
  },
  highlight: {
    border: `2px solid ${styleColors.base.walmart_blue}`,
  },
};

export default function POTDCard({ potd, deletePotd }) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const confirm = useConfirm();
  const [highlight, setHighlight] = useState(false);

  useEffect(() => {
    if (!state) return;
    if (state.newPotdId === potd.potd_id) {
      handleHighlight();
    }
  }, []);

  const handleHighlight = () => {
    setHighlight(true);
    setTimeout(() => {
      setHighlight(false);
    }, 5000);
  };

  const handleDeleteClick = () => {
    confirm({
      title: 'Confirm Deletion',
      description: 'Are you sure you want to delete this Post of the Day?',
      confirmationText: 'Yes',
      cancellationText: 'No',
    })
      .then(() => deletePotd(potd.potd_id))
      .catch(() => {
        /* do nothing on cancel */
      });
  };

  return (
    <>
      <div
        className="POTD-card-container"
        style={{
          ...styles.cardContainer,
          ...styles[potd.status].cardContainer,
          ...(highlight && styles.highlight),
        }}
      >
        <div className="POTD-card-date">
          <h4 style={{ ...styles.cardDate, ...styles[potd.status].cardDate }}>
            {moment(potd.start_date).utc().hour(0).format('ddd, MMM D')}
          </h4>
        </div>
        <div
          className="POTD-card-content-container"
          style={styles.cardContentContainer}
        >
          {potd.status === 'noPotd' ||
          (potd.status === 'inactive' && _.isEmpty(potd.message)) ? (
            <div className="noPotd-grid-container" style={styles.noPotdGrid}>
              {potd.status === 'noPotd' ? (
                <div>
                  <p style={styles.noPotd.text}>No POTD scheduled yet.</p>
                  <Button
                    sx={styles.addButton}
                    onClick={() => {
                      navigate('/potd/candidates', {
                        state: {
                          start_date: potd.start_date,
                        },
                      });
                    }}
                  >
                    <Typography variant="button">Add POTD</Typography>
                  </Button>
                </div>
              ) : (
                <p style={styles.noPotd.text}>No POTD scheduled.</p>
              )}
              <p style={styles.noPotd.helpText}>
                The last active POTD will display in-app until the next
                scheduled POTD takes effect
              </p>
            </div>
          ) : (
            <>
              <div
                className="POTD-card-img-container"
                style={styles.thumbnailContainer}
              >
                <img
                  src={potd.type === 'video' ? potd.thumbnail : potd.media}
                  alt={`Media for post ${potd.post_id}`}
                  style={styles.thumbnail}
                />
                {potd.type === 'video' && (
                  <div style={styles.videoOverlay}>
                    <PlayArrowIcon
                      sx={{ fontSize: 120 }}
                      color={styleColors.base.light}
                      style={styles.playIcon}
                    />
                  </div>
                )}
              </div>
              <p>
                {/* Truncate potd message to a preview length. Default end with ellipsis if truncated */}
                {_.truncate(potd.message, {
                  length: 120,
                  separator: ' ',
                })}
              </p>
              <div
                className="POTD-card-footer"
                style={
                  ACTIONABLE_STATUS.includes(potd.status)
                    ? styles.cardFooterGrid
                    : {}
                }
              >
                {potd.status === 'scheduled' ? (
                  <IconButton
                    sx={styles.iconButton}
                    onClick={handleDeleteClick}
                  >
                    <DeleteIcon sx={styles.icons} />
                  </IconButton>
                ) : (
                  <></>
                )}
                {potd.status === 'active' ? (
                  <IconButton
                    sx={styles.iconButton}
                    onClick={() => {
                      /* eslint-disable-next-line no-alert */
                      alert(
                        'I see you clicked the swap button! Functionality will be implemented in a future ticket.'
                      );
                    }}
                  >
                    <SwapHorizIcon sx={styles.icons} />
                  </IconButton>
                ) : (
                  <></>
                )}
                <p style={styles.storeNumber}>Store {potd.store}</p>
                {ACTIONABLE_STATUS.includes(potd.status) ? (
                  <IconButton
                    sx={styles.iconButton}
                    onClick={() => {
                      navigate('/potd/update', { state: potd });
                    }}
                  >
                    <EditNoteIcon sx={styles.icons} />
                  </IconButton>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

POTDCard.propTypes = {
  potd: PropTypes.shape({
    potd_id: PropTypes.number,
    message: PropTypes.string,
    media: PropTypes.string,
    store: PropTypes.number,
    status: PropTypes.string,
    start_date: PropTypes.string.isRequired,
    type: PropTypes.string,
    thumbnail: PropTypes.string,
    post_id: PropTypes.string,
  }).isRequired,
  deletePotd: PropTypes.func,
};
POTDCard.defaultProps = {
  deletePotd: () => {},
};
