import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button, Typography } from '@mui/material';
import { AuthContext } from 'react-oauth2-code-pkce';
import { UserContext } from './User.context';
import colors from '../colors.styles';
import config from '../config';
import logo from '../assets/MLS_logoX2.png';
import SpinningSpark from '../shared/components/SpinningSpark';

export default function Login() {
  const [pageIsLoading, setPageIsLoading] = useState(false);
  const { state, signInPingFed } = useContext(UserContext);
  const { login, token, error } = useContext(AuthContext);
  const { TENANT, APP_ENV } = config;
  const searchParams = new URLSearchParams(window.location.search);
  const auth_code = searchParams.get('code');

  useEffect(() => {
    // The presence of the authcode means we are coming back from PingFed
    // This is to prevent the login page from flashing after the redirect
    if (auth_code) setPageIsLoading(true);
  }, []);

  useEffect(() => {
    // If an error occurs, we want to stop the loading spinner and show the login button
    if (error !== null) {
      setPageIsLoading(false);
    }
  }, [error]);

  useEffect(() => {
    if (
      token &&
      !state.authenticated &&
      !state.userIsLoading &&
      !state.loginError
    ) {
      // The library doesn't share the refresh token so we have to get it from local storage
      const refresh_token = JSON.parse(
        localStorage.getItem('ROCP_refreshToken')
      );
      const tokens = {
        token,
        refresh_token,
      };
      signInPingFed(tokens);
    }
  }, [token]);

  const handleLogin = () => {
    setPageIsLoading(true);
    login();
  };

  if (!pageIsLoading) {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ minHeight: '100vh' }}
      >
        <div
          style={{
            background: colors.base.walmart_blue,
            padding: 20,
            borderRadius: 25,
            marginBottom: 20,
          }}
        >
          <img src={logo} alt="mls-logo" height="100" />
          <Typography
            variant="h4"
            style={{
              fontWeight: 'bold',
              textAlign: 'center',
              color: colors.base.spark_yellow,
            }}
          >
            Admin Panel
          </Typography>
        </div>

        {state.loginError ? (
          <Typography
            variant="subtitle2"
            style={{ color: colors.base.dark_red }}
          >
            {state.loginError}
          </Typography>
        ) : null}
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleLogin()}
        >
          <SpinningSpark isSpinning={false} height="30" marginTop="0" />
          <Typography variant="button" style={{ marginLeft: 10 }}>
            SSO Sign In
          </Typography>
        </Button>

        <Typography
          variant="h6"
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
            color: colors.base.light_blue,
          }}
        >
          {TENANT} {APP_ENV}
        </Typography>
      </Grid>
    );
  }
  return (
    <Grid container sx={{ justifyContent: 'center' }}>
      <Grid>
        <SpinningSpark height="50" />
      </Grid>
    </Grid>
  );
}
