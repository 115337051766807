import _ from 'lodash';
import CommonUtils from '../shared/utils/common.utils';
import config from '../config';

const { DEFAULT_LANGUAGE } = config;

export default class PriorityLanguageCopy {
  constructor(
    languageName = DEFAULT_LANGUAGE,
    title = '',
    body = '',
    getStartedSteps = [PriorityLanguageCopy.initGetStartedStep()],
    callsToAction = [PriorityLanguageCopy.initCallToAction()]
  ) {
    this.languageName = languageName;
    this.title = title;
    this.body = body;
    this.getStartedSteps = getStartedSteps;
    this.callsToAction = callsToAction;
  }

  isEmpty() {
    return PriorityLanguageCopy.isEmpty(this);
  }

  toAPI() {
    return PriorityLanguageCopy.toAPI(this);
  }

  static toAPI(languageCopy) {
    return {
      id: languageCopy.id,
      title: languageCopy.title,
      body: languageCopy.body,
      CTAs: languageCopy.callsToAction.map((cta, i) => ({
        ...cta,
        ordinal: i,
      })),
      get_started_text: languageCopy.getStartedSteps,
      language: languageCopy.languageName,
    };
  }

  static fromAPI(languageCopyFromApi) {
    const ctas = languageCopyFromApi.CTAs.map((cta) => ({
      id: cta.suggestionCTA_id,
      copy: cta.copy,
      link: cta.link,
      ordinal: cta.ordinal,
    }));

    return new PriorityLanguageCopy(
      languageCopyFromApi.language,
      languageCopyFromApi.title,
      languageCopyFromApi.body,
      languageCopyFromApi.get_started_text,
      ctas
    );
  }

  static filterEmptyLanguages(languages) {
    const nonEmptyLanguages = _.pickBy(
      languages,
      (copy) => !PriorityLanguageCopy.isEmpty(copy)
    );

    const nonEmptyAttrs = _.map(nonEmptyLanguages, (language) => {
      const callsToAction = language.callsToAction.filter(
        (cta) => !CommonUtils.isEmptyDeep(_.pick(cta, ['copy', 'link']))
      );
      const getStartedSteps = language.getStartedSteps.filter(
        (steps) => !CommonUtils.isEmptyDeep(steps)
      );
      return {
        ...language,
        callsToAction,
        getStartedSteps,
      };
    });

    return nonEmptyAttrs;
  }

  static isEmpty(languageCopy) {
    if (!languageCopy) return true;

    const fieldsToCheck = ['title', 'body', 'getStartedSteps', 'callsToAction'];
    return _.every(fieldsToCheck, (field) =>
      CommonUtils.isEmptyDeep(languageCopy[field])
    );
  }

  static initGetStartedStep() {
    return '';
  }

  static initCallToAction() {
    return { id: 0, copy: '', link: '' };
  }
}
