/* eslint-disable react/jsx-no-bind */
// React page that uses material ui to display a table of user data
// the table displays data for a single user
// the table's first column has the user details component
// the table's second column will have radio buttons for the user's role

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';

import UserService from './Users.api';
import UserDetails from './UserDetails';

import SpinningSpark from '../shared/components/SpinningSpark';
import UserPermissions from './UserPermissions';
import LoggingService from '../shared/Logging.api';

export default function User() {
  const { platform_id } = useParams();

  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (platform_id) getUser();
  }, []);

  function getUser() {
    UserService.getUser(platform_id)
      .then((response) => {
        setUser(response.data);
        setLoading(false);
      })
      .catch((error) => {
        LoggingService.error('Error getting user', error);
        setLoading(false);
      });
  }

  return (
    <Grid
      container
      direction="column"
      style={{
        minHeight: '100vh',
        marginTop: 20,
        alignItems: 'center',
      }}
    >
      {loading ? (
        <SpinningSpark isSpinning={loading} />
      ) : (
        <>
          <Grid
            container
            direction="row"
            justify="start"
            alignItems="initial"
            style={{
              minHeight: '100vh',
              marginTop: 20,
            }}
          >
            <UserDetails user={user} />
            <UserPermissions user={user} getUser={getUser} />
          </Grid>
        </>
      )}
    </Grid>
  );
}
