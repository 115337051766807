import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import WarningIcon from '@mui/icons-material/Warning';

import styleColors from '../colors.styles';
import config from '../config';
import formValidationsUtils from '../shared/utils/formValidations.utils';

const { DEFAULT_LANGUAGE, ALL_LANGUAGES } = config;

const ALL_LANGUAGES_MAP = {
  en: 'English',
  es: 'Spanish',
  fr: 'French',
};

const styles = {
  button: {
    textAlign: 'left',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0px',
    opacity: 1,
    paddingRight: 0,
    paddingLeft: 0,
  },
};

export default function WhyItWorks({
  whyWorksData,
  setWhyWorksData,
  langWithErrors,
  setLangWithErrors,
}) {
  const [activeLanguageKey, setActiveLanguageKey] = useState(DEFAULT_LANGUAGE);

  const { required } = formValidationsUtils;

  useEffect(() => {
    const whyItWorksInitialData = {};
    ALL_LANGUAGES.forEach((language) => {
      whyItWorksInitialData[language] = _.isEmpty(whyWorksData[language])
        ? _.times(3, () => ({ textValue: '' }))
        : whyWorksData[language].map((textValue) => ({ textValue }));
    });
    if (_.isEmpty(whyWorksData)) {
      setLangWithErrors(ALL_LANGUAGES);
    }
    setWhyWorksData(whyItWorksInitialData);
  }, []);

  // Events
  const handleInputChange = (event, index) => {
    const newValues = [...whyWorksData[activeLanguageKey]];
    newValues[index].textValue = event.target.value;

    setWhyWorksData({ ...whyWorksData, [activeLanguageKey]: newValues });

    if (index === 0) {
      const fieldIsEmpty = !required().isValid(newValues[index].textValue);
      setLangWithErrors((prevErrors) => {
        const errors = [...prevErrors];
        if (fieldIsEmpty && !errors.includes(activeLanguageKey)) {
          errors.push(activeLanguageKey);
        } else if (!fieldIsEmpty && errors.includes(activeLanguageKey)) {
          const indexToRemove = errors.indexOf(activeLanguageKey);
          errors.splice(indexToRemove, 1);
        }
        return errors;
      });
    }
  };

  const handleAddInput = () => {
    setWhyWorksData({
      ...whyWorksData,
      [activeLanguageKey]: [
        ...whyWorksData[activeLanguageKey],
        { textValue: '' },
      ],
    });
  };

  const handleRemove = () => {
    const newValues = [...whyWorksData[activeLanguageKey]];
    newValues.pop();
    setWhyWorksData({
      ...whyWorksData,
      [activeLanguageKey]: [...newValues],
    });
  };

  // Functions
  const renderInputs = () => {
    return whyWorksData[activeLanguageKey]?.map((input, index) => {
      const id = `input-${index}`;
      const fieldHasError =
        index === 0 && langWithErrors.includes(activeLanguageKey);
      return (
        <div key={id}>
          <TextField
            variant="standard"
            fullWidth
            sx={{
              background: '#FFFFFF',
              borderRadius: '16px',
              opacity: '1',
              marginBottom: '24px',
              padding: '8px 16px',
              border: fieldHasError ? '1px solid #d32f2f' : 'none',
              boxShadow: fieldHasError ? '0 0 0 1px #d32f2f' : 'none',
            }}
            InputProps={{
              disableUnderline: true,
            }}
            value={input.textValue}
            placeholder="Enter text here"
            onChange={(e) => handleInputChange(e, index)}
            error={fieldHasError}
          />
          {fieldHasError && (
            <Box sx={{ marginTop: '-24px' }}>
              <Typography
                variant="caption"
                color="error"
                sx={{ marginLeft: '12px' }}
              >
                This field is required
              </Typography>
            </Box>
          )}
        </div>
      );
    });
  };

  const renderLanguageButtons = () => {
    return ALL_LANGUAGES.map((languageCode) => {
      return (
        <Box
          key={languageCode}
          sx={{
            marginRight: '36px',
            ...(activeLanguageKey === languageCode
              ? { borderBottom: '3px solid #306FC6' }
              : { borderBottom: '#' }),
          }}
        >
          <Button
            disableRipple
            style={styles.button}
            sx={
              activeLanguageKey === languageCode
                ? { color: '#1C2126' }
                : { color: '#8B8B8B' }
            }
            onClick={() => setActiveLanguageKey(languageCode)}
          >
            {ALL_LANGUAGES_MAP[languageCode]}
            {langWithErrors.includes(languageCode) && (
              <WarningIcon
                color="error"
                sx={{ marginTop: '-5px', marginLeft: '5px' }}
              />
            )}
          </Button>
        </Box>
      );
    });
  };

  return (
    <Grid item xs={12} sx={{ marginBottom: '2vh' }}>
      <Box
        sx={{
          background: `${styleColors.base.almost_white_blue}`,
          borderRadius: '16px',
          opacity: '1',
          paddingTop: '7px',
          paddingLeft: '19px',
          paddingRight: '21px',
          paddingBottom: '27px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            paddingTop: '10px',
            paddingBottom: '10px',
          }}
        >
          {renderLanguageButtons()}
        </Box>
        {langWithErrors.length > 0 && (
          <Box>
            <Typography color="error">
              Please fill all required fields
            </Typography>
          </Box>
        )}
        <Box>
          <Typography
            sx={{
              marginTop: langWithErrors.length > 0 ? '27px' : '51px',
              marginBottom: '32px',
              textAlign: 'left',
              fontFamily: 'Helvetica Neue',
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '25px',
              letterSpacing: '0px',
              color: '#000000',
              opacity: 1,
            }}
          >
            Why It Works
          </Typography>
        </Box>

        <Box sx={{ marginLeft: '7px' }}>{renderInputs()}</Box>

        <Box sx={{ marginLeft: '15px' }}>
          <Button
            sx={{ padding: '0px', minWidth: 0, marginRight: '16px' }}
            disableRipple
            onClick={handleAddInput}
          >
            <AddCircleOutlineRoundedIcon sx={{ color: '#707070' }} />
          </Button>
          <Button
            sx={{ padding: '0px', minWidth: 0 }}
            disableRipple
            disabled={whyWorksData[activeLanguageKey]?.length < 2}
            onClick={handleRemove}
          >
            <RemoveCircleOutlineRoundedIcon sx={{ color: '#707070' }} />
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}

WhyItWorks.propTypes = {
  setWhyWorksData: PropTypes.func,
  whyWorksData: PropTypes.shape({}),
  setLangWithErrors: PropTypes.func,
  langWithErrors: PropTypes.arrayOf(PropTypes.string),
};

WhyItWorks.defaultProps = {
  setWhyWorksData: () => {},
  whyWorksData: {},
  setLangWithErrors: () => {},
  langWithErrors: [],
};
