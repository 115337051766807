import React, { createContext, useState } from 'react';
import { Alert, AlertTitle, Fade, Snackbar } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

export const AlertsContext = createContext();

/**
 * This context will provide us a way to show toast alerts one at a time.
 * There are 4 types of alerts that we can use [info, success, warning, error]
 * Every alert auto closes after 5 seconds unless is an error, for this you need
 * to click in the close button to dismiss the alert.
 */
export default function AlertsProvider({ children }) {
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);

  function showAlert(alertMessage, alertType = 'info') {
    setType(alertType);
    setMessage(alertMessage);
    setShow(true);
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setShow(false);
  };

  return (
    <AlertsContext.Provider value={{ showAlert }}>
      {children}
      {show && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={alert.type === 'error' ? null : 5000}
          // eslint-disable-next-line react/jsx-props-no-spreading
          TransitionComponent={(props) => <Fade {...props} />}
          onClose={handleClose}
        >
          <Alert severity={type} onClose={handleClose}>
            <AlertTitle>{_.capitalize(type)}</AlertTitle>
            {message}
          </Alert>
        </Snackbar>
      )}
    </AlertsContext.Provider>
  );
}

AlertsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
