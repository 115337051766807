/* eslint-disable react/jsx-no-bind */
import _ from 'lodash';
import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import moment from 'moment-timezone';
import POTDContainer from './POTDContainer';
import POTDServices from './POTD.api';
import LoggingApi from '../shared/Logging.api';
import SpinningSpark from '../shared/components/SpinningSpark';
import createPOTDFormValidationsUtils from '../shared/utils/POTDFormValidations.utils';
import POTDForm from './forms/POTDForm';
import { AlertsContext } from '../shared/alerts/Alerts.context';

// this is to avoid required field errors for this hidden values
const defaultEmptyCropValue = {
  crop_x_position: 0,
  crop_y_position: 0,
  crop_width: 0,
  crop_height: 0,
};

export default function POTDEdit() {
  const { state: potd } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [potdList, setPotdList] = useState([]);
  const { showAlert } = useContext(AlertsContext);
  const { removeEmptyWhyItWorksData } = createPOTDFormValidationsUtils;
  const disableDateForActivePotd =
    potd.status === 'active' &&
    moment(potd.start_date).isSameOrBefore(moment(), 'day');

  useEffect(() => {
    // Redirect to POTD screen if a post has not been selected
    if (_.isEmpty(potd)) {
      showAlert('You need to select a Post of the Day first', 'warning');
      navigate('/potd', { replace: true });
      return;
    }

    POTDServices.getPotdList()
      .then((res) => {
        setFormData({
          ...potd,
          ...defaultEmptyCropValue,
          media: potd.media,
          market: potd.market.toString(),
          store: potd.store.toString(),
          start_date: disableDateForActivePotd
            ? moment.utc()
            : moment(potd.start_date).utc().startOf('day'),
          disableDateForActivePotd,
        });
        setPotdList(res.data);
      })
      .catch((error) => LoggingApi.error('Error getting PotD List', error))
      .finally(() => setIsLoading(false));
  }, []);

  function updatePotd() {
    const potdData = {
      post: {
        ...formData,
        potd_id: disableDateForActivePotd ? null : formData.potd_id,
        why_it_works: removeEmptyWhyItWorksData(formData.why_it_works),
        start_date: formData.start_date.format(),
        media: formData.media,
        region: Number(formData.region),
        market: Number(formData.market),
        store: Number(formData.store),
      },
    };

    POTDServices.postPotd(potdData)
      .then((response) =>
        navigate('/potd', {
          replace: true,
          state: { newPotdId: response.data.potd_id },
        })
      )
      .catch((error) => {
        showAlert('Error updating the PotD', 'error');
        LoggingApi.error('Error updating PotD', error);
      });
  }

  return (
    <POTDContainer>
      {isLoading ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <SpinningSpark isSpinning />
        </Box>
      ) : (
        <POTDForm
          data={formData}
          setData={setFormData}
          potdList={potdList}
          onSubmit={updatePotd}
          isUpdate
        />
      )}
    </POTDContainer>
  );
}
