import AppService from '../shared/App.api';

export default {
  getCandidateDetails,
  getCandidates,
  getPotdList,
  postPotd,
  deletePotd,
};

let cachedPotdList;
const cachedCandidates = {};
const cachedCandidateDetails = {};

async function getCandidateDetails(postId) {
  if (!cachedCandidateDetails[postId]) {
    const path = `/recognition/candidates/${postId}`;
    cachedCandidateDetails[postId] = await AppService.get(path);
  }
  return cachedCandidateDetails[postId];
}

async function getCandidates(searchTerm) {
  let path = `/recognition/candidates?limit=20`;
  if (searchTerm) {
    path = `${path}&search=${searchTerm}`;
  }
  if (!cachedCandidates[path]) {
    cachedCandidates[path] = await AppService.get(path);
  }
  return cachedCandidates[path];
}

async function getPotdList() {
  if (!cachedPotdList) {
    const path = `/recognition/post-of-the-day-list`;
    const response = await AppService.get(path);
    cachedPotdList = response;
  }
  return cachedPotdList;
}

async function postPotd(data) {
  const path = '/recognition/post-of-the-day';
  const response = await AppService.postJson(path, data);
  cachedPotdList = null;
  return response;
}

async function deletePotd(postId) {
  const path = `/recognition/post-of-the-day/${postId}`;
  cachedPotdList = null;
  return AppService.deleteCall(path);
}
