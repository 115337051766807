import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { createStyles } from '@mui/styles';
import { Delete } from '@mui/icons-material';
import Priority from '../Priority.class';
import PrioritiesService from '../Priorities.api';

export default function PriorityDeleteForm({
  priority,
  onAPISuccess,
  onAPIError,
  spacing = 1,
}) {
  const languageCopy = Priority.defaultOrFirstLanguage(priority.languages);
  const title = languageCopy?.title ?? '[No Title]';
  const styles = createStyles({
    divider: {
      margin: '20px 0',
    },
  });

  return (
    <Box>
      <Divider sx={styles.divider} />
      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          <Typography>
            Are you sure you want to end this priority? This action cannot be
            undone.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary" fontWeight="bold">
            {title}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={styles.divider} />

      <Grid container spacing={spacing}>
        <Grid item xs={12}>
          <Button
            type="button"
            variant="contained"
            fullWidth
            color="error"
            size="large"
            onClick={() =>
              PrioritiesService.deletePriority(priority.id)
                .then(onAPISuccess)
                .catch(onAPIError)
            }
            startIcon={<Delete />}
          >
            Yes (Delete)
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

PriorityDeleteForm.propTypes = {
  priority: PropTypes.instanceOf(Priority).isRequired,
  onAPISuccess: PropTypes.func,
  onAPIError: PropTypes.func,
  spacing: PropTypes.number,
};

PriorityDeleteForm.defaultProps = {
  onAPISuccess: () => {},
  onAPIError: () => {},
  spacing: 1,
};
