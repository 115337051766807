import React from 'react';

export default function Unauthorized() {
  return (
    <div>
      <h1>Unauthorized</h1>
      <p>
        You do not have access to the MLS Admin Panel. Please contact{' '}
        <strong>mylocalsocial@bn.co</strong> to request access.
      </p>
    </div>
  );
}
