/* eslint-disable react/jsx-no-bind */
import _ from 'lodash';
import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import moment from 'moment-timezone';
import POTDContainer from './POTDContainer';
import POTDServices from './POTD.api';
import LoggingApi from '../shared/Logging.api';
import SpinningSpark from '../shared/components/SpinningSpark';
import createPOTDFormValidationsUtils from '../shared/utils/POTDFormValidations.utils';
import POTDForm from './forms/POTDForm';
import { AlertsContext } from '../shared/alerts/Alerts.context';

const initialFormData = {
  author: '',
  message: '',
  page_name: '',
  market: null,
  region: null,
  store: null,
};

export default function POTDCreate() {
  const { state: potd } = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(
    { ...initialFormData, ...potd } || initialFormData
  );
  const [potdList, setPotdList] = useState([]);
  const { showAlert } = useContext(AlertsContext);

  const { removeEmptyWhyItWorksData } = createPOTDFormValidationsUtils;

  useEffect(() => {
    // Redirect to Candidates screen if a post has not been selected
    if (_.isEmpty(potd)) {
      showAlert(
        'You need to select a Post of the Day candidate first',
        'warning'
      );
      navigate('/potd/candidates', { replace: true });
      return;
    }

    POTDServices.getCandidateDetails(potd.facebook_id)
      .then(({ data }) => {
        setFormData({
          ...formData,
          author: data.created_by,
          region: data.region,
          market: data.market,
          store: potd.store.toString(),
          start_date: !_.isEmpty(potd.start_date)
            ? moment(potd.start_date).utc().startOf('day')
            : '',
          media: data.bn_original_media,
        });
      })
      .catch((error) => {
        LoggingApi.error('Error getting post details', error);
        showAlert('Failed to get post details.', 'error');
      })
      .finally(() => setIsLoading(false));

    POTDServices.getPotdList()
      .then((res) => setPotdList(res.data))
      .catch((error) => LoggingApi.error('Error getting PotD List', error));
  }, []);

  function createPotd() {
    const potdData = {
      post: {
        ...formData,
        why_it_works: removeEmptyWhyItWorksData(formData.why_it_works),
        start_date: formData.start_date.format(),
        media: formData.media,
        region: Number(formData.region),
        market: Number(formData.market),
        store: Number(formData.store),
      },
    };

    POTDServices.postPotd(potdData)
      .then((response) =>
        navigate('/potd', {
          replace: true,
          state: { newPotdId: response.data.potd_id },
        })
      )
      .catch((error) => {
        showAlert('Error creating the PotD', 'error');
        LoggingApi.error('Error creating PotD', error);
      });
  }

  return (
    <>
      <POTDContainer>
        {isLoading ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
          >
            <SpinningSpark isSpinning />
          </Box>
        ) : (
          <POTDForm
            data={formData}
            setData={setFormData}
            potdList={potdList}
            onSubmit={createPotd}
          />
        )}
      </POTDContainer>
    </>
  );
}
