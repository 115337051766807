import _ from 'lodash';
import moment from 'moment-timezone';

import config from '../config';
import CommonUtils from '../shared/utils/common.utils';
import ObjectUtils from '../shared/utils/object.utils';
import PriorityLanguageCopy from './PriorityLanguageCopy.class';
import LoggingService from '../shared/Logging.api';

const { ALL_LANGUAGES, DEFAULT_LANGUAGE } = config;

export default class Priority {
  constructor(id, languages, targeting, lifetime, keywords) {
    this.id = id || '';
    this.languages = languages || Priority.initLanguages();
    this.targeting = targeting || Priority.initTargeting();
    this.keywords = keywords || [];

    const lifetimeOrDefault = lifetime || Priority.initLifetime();
    this.startDate = lifetimeOrDefault.startDate;
    this.endDate = lifetimeOrDefault.endDate;
  }

  toAPI() {
    return Priority.toAPI(this);
  }

  isLanguageEmpty(languageKey) {
    return PriorityLanguageCopy.isEmpty(this.languages[languageKey]);
  }

  attachSearchTerm() {
    return Priority.attachSearchTerm(this);
  }

  defaultOrFirstLanguage() {
    return Priority.defaultOrFirstLanguage(this.languages);
  }

  static attachSearchTerm(priority) {
    const withSearchable = { ...priority };
    if (_.has(withSearchable, 'searchable')) {
      delete withSearchable.searchable; // avoid doubling search string length on successive calls
    }

    withSearchable.searchable = ObjectUtils.flattenToStr(withSearchable);
    return withSearchable;
  }

  static defaultOrFirstLanguage(languages) {
    const defaultLang = _.get(languages, DEFAULT_LANGUAGE);
    const firstLang = _.get(languages, _.first(_.keys(languages)));
    return defaultLang ?? firstLang ?? null;
  }

  static initLanguages(allowedLanguages = ALL_LANGUAGES) {
    const output = allowedLanguages.reduce(
      (accumulator, languageKey) => ({
        ...accumulator,
        ...{ [languageKey]: new PriorityLanguageCopy(languageKey) },
      }),
      {}
    );

    return output;
  }

  static initTargeting() {
    return {
      storeTypes: [],
      serviceDefinitionIds: [],
      regions: [],
      markets: [],
      states: [],
      storeNumbers: [],
    };
  }

  static initLifetime() {
    const today = new Date().setHours(0, 0, 0, 0);

    return {
      startDate: moment(today),
      endDate: null,
    };
  }

  // mapping functions
  static toAPI(priority) {
    const languages = Priority.languagesToAPI(priority.languages);
    const targeting = Priority.targetingToAPI(priority.targeting);
    const lifetime = {
      start_date: priority.startDate.toISOString(),
      end_date: priority.endDate?.toISOString() ?? null,
    };

    return {
      id: priority.id,
      languages,
      targeting,
      ...lifetime,
      keyword: priority.keywords,
    };
  }

  static fromAPI(priority) {
    const languages = Priority.languagesFromAPI(priority.languages);
    const targeting = Priority.targetingFromAPI(priority.targeting);
    const lifetime = {
      startDate: CommonUtils.formatISO(priority.start_date),
      endDate: priority.end_date && CommonUtils.formatISO(priority.end_date),
    };

    return {
      id: priority.id,
      languages,
      targeting,
      ...lifetime,
      availableLanguages: Object.keys(languages).join(', '),
      keyword: priority.keyword,
    };
  }

  static languagesToAPI(languages) {
    return Object.keys(languages).map((lang) =>
      PriorityLanguageCopy.toAPI(languages[lang])
    );
  }

  static languagesFromAPI(languages) {
    return languages.reduce(
      (output, langData) => ({
        ...output,
        [langData.language]: PriorityLanguageCopy.fromAPI(langData),
      }),
      {}
    );
  }

  static targetingFromAPI(targetingString) {
    let targeting = {};
    // Only parse if it's actually a string
    if (_.isObject(targetingString)) {
      targeting = targetingString;
    } else if (_.isString(targetingString)) {
      try {
        targeting = JSON.parse(targetingString);
      } catch (err) {
        LoggingService.error('Failed to parse targeting string:', err);
        targeting = {};
      }
    } else {
      LoggingService.error('Unexpected value for targeting', targetingString);
    }

    return {
      storeTypes: targeting?.store_type || [],
      serviceDefinitionIds: targeting?.service_definition_id || [],
      regions: targeting?.region || [],
      markets: targeting?.market || [],
      states: targeting?.state || [],
      storeNumbers: targeting?.store_number || [],
    };
  }

  static targetingToAPI(targeting) {
    const targetingObj = {
      store_type: targeting.storeTypes || null,
      service_definition_id: targeting.serviceDefinitionIds || null,
      region: targeting.regions || null,
      market: targeting.markets || null,
      state: targeting.states || null,
      store_number: targeting.storeNumbers || null,
    };
    return _.omitBy(targetingObj, _.isEmpty);
  }
}
