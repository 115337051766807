import React from 'react';
import { Button, Stack, TableCell, TableRow } from '@mui/material';
import { createStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function CustomTableRow({ row, columns, actions }) {
  const styles = createStyles({
    compactOrderedList: {
      paddingLeft: 0,
      listStyle: 'inside decimal',
    },
    actionButton: {
      textTransform: 'none',
    },
  });

  return (
    <TableRow>
      {_.map(columns, (column) => {
        return (
          <TableCell key={column.id}>
            {_.has(column, 'display')
              ? column.display(_.get(row, column.path, []))
              : _.get(row, column.path)}
          </TableCell>
        );
      })}
      <TableCell key="actions" align="center">
        <Stack spacing={0}>
          {_.map(actions, (action) => {
            return (
              <Button
                key={action.id}
                type="button"
                sx={styles.actionButton}
                startIcon={action.icon}
                color={action.textColor}
                onClick={() => action.onClick(row)}
              >
                {action.name}
              </Button>
            );
          })}
        </Stack>
      </TableCell>
    </TableRow>
  );
}

CustomTableRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({})),
};

CustomTableRow.defaultProps = {
  actions: [],
};
