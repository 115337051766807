/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import PropTypes from 'prop-types';

import _ from 'lodash';
import moment from 'moment-timezone';

import Priority from '../Priority.class';
import FormValidationsUtils from '../../shared/utils/formValidations.utils';

const { dateSequence } = FormValidationsUtils;

export default function PriorityLifetimeForm({
  onStateChanged,
  initialLifetime,
  isValidating,
}) {
  const [lifetime, setLifetime] = useState(
    initialLifetime
      ? {
          startDate: moment(initialLifetime.startDate),
          endDate: initialLifetime.endDate && moment(initialLifetime.endDate),
        }
      : Priority.initLifetime()
  );

  const [validationErrors, setValidationErrors] = useState({});
  function validate() {
    const newErrors = {};

    if (isValidating) {
      const { startDate, endDate } = lifetime;
      if (endDate && !dateSequence().isValid(startDate, endDate)) {
        newErrors.summary = dateSequence().helperText;
      }
    }

    setValidationErrors(newErrors);
    return _.isEmpty(newErrors);
  }

  useEffect(() => {
    onStateChanged(lifetime, validate());
  }, [lifetime]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Dates Active</Typography>
      </Grid>

      {_.has(validationErrors, 'summary') && (
        <Grid item xs={12}>
          <Typography color="error">{validationErrors.summary}</Typography>
        </Grid>
      )}

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Grid item xs={6}>
          <DateTimePicker
            label="Start Date"
            sx={{ width: '100%' }} // fullWidth doesn't work on this component :/
            value={lifetime.startDate}
            onChange={(value) => {
              setLifetime({ ...lifetime, startDate: value });
            }}
          />
        </Grid>

        {lifetime.endDate && (
          <>
            <Grid item xs={6} display="flex" alignItems="center">
              <DateTimePicker
                label="End Date"
                sx={{ width: '100%' }}
                value={lifetime.endDate}
                onChange={(value) => {
                  setLifetime({ ...lifetime, endDate: value });
                }}
              />
            </Grid>

            <Grid item xs={6}>
              {/* padding */ ' '}
            </Grid>
          </>
        )}

        <Grid item xs={6} display="flex" alignItems="center">
          <FormControlLabel
            label="Evergreen"
            labelPlacement="end"
            control={
              <Checkbox
                color="primary"
                checked={!lifetime.endDate}
                onChange={(e) =>
                  setLifetime({
                    ...lifetime,
                    endDate: e.target.checked
                      ? null
                      : moment(lifetime.startDate).add(1, 'day'),
                  })
                }
              />
            }
          />
        </Grid>
      </LocalizationProvider>
    </>
  );
}

PriorityLifetimeForm.propTypes = {
  onStateChanged: PropTypes.func.isRequired,
  initialLifetime: PropTypes.shape({
    startDate: PropTypes.instanceOf(moment),
    endDate: PropTypes.instanceOf(moment),
  }),
  isValidating: PropTypes.bool,
};

PriorityLifetimeForm.defaultProps = {
  initialLifetime: null,
  isValidating: false,
};
