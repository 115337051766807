import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { List, ListItemButton, ListItemText } from '@mui/material';
import styleColors from '../colors.styles';

const StickerCategoryActions = forwardRef((props, ref) => {
  const styles = {
    menuList: {
      position: 'absolute',
      index: 150,
      top: 60,
      right: 10,
      backgroundColor: styleColors.base.almost_white_blue,
      borderRadius: '3px',
      zIndex: 150,
    },
  };

  const { onEdit, onArchive, onDelete } = props;

  return (
    <List ref={ref} style={styles.menuList}>
      <ListItemButton onClick={onEdit}>
        <ListItemText primary="Edit Category" />
      </ListItemButton>
      <ListItemButton onClick={onArchive}>
        <ListItemText primary="Archive Category" />
      </ListItemButton>
      <ListItemButton onClick={onDelete}>
        <ListItemText primary="Permanently Delete Category" />
      </ListItemButton>
    </List>
  );
});

export default StickerCategoryActions;

StickerCategoryActions.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
