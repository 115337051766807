import AppService from '../shared/App.api';
import Priority from './Priority.class';

export default {
  getPriorities,
  createPriority,
  updatePriority,
  updateOrCreatePriority,
  deletePriority,
};

const prioritiesEndpoint = `/suggestions`;

async function getPriorities() {
  const path = `${prioritiesEndpoint}/admin`;
  const opts = {
    params: {
      stores: 'all',
      includeInactive: false,
      includeFuture: true,
    },
  };

  const response = await AppService.get(path, opts);
  return response.data.map(Priority.fromAPI);
}

async function createPriority(priority) {
  const path = prioritiesEndpoint;
  const reqData = Priority.toAPI(priority);
  const response = await AppService.postJson(path, reqData);
  return Priority.fromAPI(response.data);
}

async function updatePriority(id, priority) {
  const path = `${prioritiesEndpoint}/${id}`;
  const reqData = Priority.toAPI(priority);
  const response = await AppService.put(path, reqData);
  return Priority.fromAPI(response.data);
}

async function updateOrCreatePriority(priority) {
  return priority.id
    ? updatePriority(priority.id, priority)
    : createPriority(priority);
}

function deletePriority(id) {
  const path = `${prioritiesEndpoint}/${id}`;
  return AppService.deleteCall(path);
}
