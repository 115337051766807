import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import socialChampsService from './SocialChamps.service';
import { AlertsContext } from '../shared/alerts/Alerts.context';

export default function SocialChamps() {
  const [jsonResult, setJsonResult] = useState(null);
  const [errors, setErrors] = useState(null);
  const fileInputRef = useRef(null);
  const { showAlert } = useContext(AlertsContext);

  const styles = {
    mainContainer: {
      marginTop: '6px',
    },
    pageTitle: {
      paddingTop: '20px',
    },
    fileUploadInput: {
      display: 'none',
    },
    uploadButton: {
      width: '200px',
      marginTop: '50px',
      marginBottom: '20px',
    },
  };

  useEffect(() => {
    if (!errors) return;
    showAlert(`${errors}`, 'error');
  }, [errors]);

  useEffect(() => {
    if (!jsonResult) return;
    showAlert('File uploaded successfully!', 'success');
  }, [jsonResult]);

  return (
    <Grid container direction="column" spacing={2} style={styles.mainContainer}>
      <Grid item>
        <Stack spacing={2}>
          <Typography variant="h4" align="left">
            Social Champs
          </Typography>

          <input
            type="file"
            ref={fileInputRef}
            style={styles.fileUploadInput}
            accept=".csv"
            onChange={(event) => {
              socialChampsService.handleFileUpload(
                event,
                setJsonResult,
                setErrors
              );
              if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
            }}
          />

          <Button
            variant="contained"
            color="primary"
            style={styles.uploadButton}
            onClick={() => {
              fileInputRef.current.click();
            }}
          >
            Upload CSV
          </Button>
          {jsonResult && (
            <Box>
              <Typography variant="body1">
                Approved:{' '}
                <pre>{JSON.stringify(jsonResult.approved, null, 2)}</pre>
              </Typography>
              <Typography variant="body1">
                Rejected:{' '}
                <pre>{JSON.stringify(jsonResult.rejected, null, 2)}</pre>
              </Typography>
            </Box>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}
