/* eslint-disable react/jsx-no-bind */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  TextField,
  Button,
  CardMedia,
  Tooltip,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useConfirm } from 'material-ui-confirm';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';
import POTDWhyItWorks from '../POTDWhyItWorks';
import styleColors from '../../colors.styles';
import POTDFormValidationsUtils from '../../shared/utils/POTDFormValidations.utils';
import { AlertsContext } from '../../shared/alerts/Alerts.context';

const styles = {
  scheduleTitle: {
    color: styleColors.base.walmart_blue,
    textAlign: 'left',
    fontSize: '2.5rem',
    padding: '0 1vw 1vh 1vw',
  },
  routePath: {
    color: styleColors.base.dark_gray,
    textAlign: 'left',
    fontSize: '1.5rem',
    marginLeft: '1vw',
    paddingBottom: '1rem',
  },
  createForm: {
    backgroundColor: styleColors.base.light,
    borderRadius: '14px',
    border: `1px solid ${styleColors.base.dark_gray}`,
    minHeight: '20vh',
    margin: '0vw 2vw 2vw 2vw',
    padding: '3vh 3vw 2vh 3vw',
  },
  postMedia: {
    marginBottom: '1vw',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  inputTitle: {
    fontSize: '1.2rem',
    marginBottom: '.5vw',
  },
  postMsg: {
    width: '100%',
    marginBottom: '1vw',
    borderRadius: '16px',
    backgroundColor: styleColors.base.almost_white_blue,
  },
  cancelButton: {
    backgroundColor: styleColors.base.dark_gray,
    color: styleColors.base.light,
    borderRadius: '100px',
    padding: '1vh 3vw',
    '&:hover': {
      backgroundColor: styleColors.base.red,
    },
  },
  submitButton: {
    backgroundColor: styleColors.base.walmart_blue,
    color: styleColors.base.light,
    borderRadius: '100px',
    padding: '1vh 3vw',
    '&:hover': {
      backgroundColor: styleColors.base.very_light_blue,
    },
  },
  detailsInput: {
    width: '100%',
    marginBottom: '2vh',
    borderRadius: '16px',
  },
  whyWorks: {
    width: '100%',
    marginBottom: '1vw',
    borderRadius: '16px',
    backgroundColor: styleColors.base.almost_white_blue,
    minHeight: '43vh',
  },
  startDateInput: {
    backgroundColor: styleColors.base.almost_white_blue,
    marginBottom: '1vw',
  },
  buttonRow: {
    width: '100%',
  },
};

export default function POTDForm({
  data,
  setData,
  potdList,
  onSubmit,
  isUpdate,
}) {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { showAlert } = useContext(AlertsContext);
  const [dateWarning, setDateWarning] = useState(false);
  const [dateIsInvalid, setDateIsInvalid] = useState(false);
  const [langWithErrors, setLangWithErrors] = useState([]);
  const [fieldsWithErrors, setFieldsWithErrors] = useState({});
  const { validatePOTDDate, validatePOTDField, validatePOTDAllFields } =
    POTDFormValidationsUtils;

  useEffect(() => {
    setFieldsWithErrors(validatePOTDAllFields(data));
    if (!data.start_date) {
      setDateIsInvalid(true);
    }
  }, [data.author]);

  // Events
  function handleInputChange({ target: { name, value } }) {
    const updatedData = { ...data, [name]: value };
    setData(updatedData);
    setFieldsWithErrors(validatePOTDField(name, fieldsWithErrors, updatedData));
  }

  function handleDate(newDateValue) {
    setDateIsInvalid(false);
    const todayDate = moment().startOf('day');
    if (newDateValue.isSameOrAfter(todayDate, 'day')) {
      setDateWarning(validatePOTDDate(newDateValue, potdList));
      setData({ ...data, start_date: newDateValue });
    } else {
      setDateWarning(false);
      setDateIsInvalid(true);
    }
  }

  function renderInputErrorMessage(formName) {
    if (_.has(fieldsWithErrors, formName)) {
      return (
        <Box sx={{ marginTop: '-14px' }}>
          <Typography
            variant="caption"
            color="error"
            sx={{ marginLeft: '12px' }}
          >
            {fieldsWithErrors[formName]}
          </Typography>
        </Box>
      );
    }
    return null;
  }

  function renderDateErrorMessage(typeOfMessage) {
    const messageForErrors = {
      warning: 'There is already a PotD scheduled for this date',
      invalidDate: 'Please select a valid date',
    };
    return (
      <Box sx={{ marginTop: '-15px' }}>
        <Typography
          variant="caption"
          sx={{
            marginLeft: '0px',
            color: typeOfMessage === 'warning' ? '#ff9800' : '#d32f2f',
          }}
        >
          {messageForErrors[typeOfMessage]}
        </Typography>
      </Box>
    );
  }

  function handleSubmitClick() {
    if (_.isEmpty(validatePOTDAllFields(data)) && _.isEmpty(langWithErrors)) {
      if (dateWarning) {
        confirm({
          title: 'Schedule Conflict Warning',
          description:
            'There is already a Post of the Day scheduled for this date, are you sure you want to replace the selection?',
          confirmationText: 'Yes',
          cancellationText: 'No',
        })
          .then(() => {
            onSubmit();
          })
          .catch(() => {
            /* do nothing on cancel */
          });
      } else {
        onSubmit();
      }
    } else {
      showAlert('Please fill all required fields', 'warning');
    }
  }
  function handleCancelClick() {
    confirm({
      title: 'Are you sure you want to go back?',
      confirmationText: 'Yes',
      cancellationText: 'No',
    })
      .then(() => navigate('/potd'))
      .catch(() => {
        /* do nothing on cancel */
      });
  }

  return (
    <>
      <Grid container direction="row">
        <Typography variant="h4" style={styles.routePath}>
          {'View POTD Schedule > POTD Candidates > Schedule POTD'}
        </Typography>
      </Grid>
      <Grid container direction="row">
        <Typography variant="h2" style={styles.scheduleTitle}>
          {isUpdate ? 'Update Post of the Day' : 'Schedule Post of the Day'}
        </Typography>
      </Grid>
      <div className="POTD-create-form" style={styles.createForm}>
        <Grid
          container
          direction="row"
          wrap="nowrap"
          justifyContent="space-between"
          spacing={5}
        >
          <Grid item xs className="far-left-col">
            {data.type === 'photo' ? (
              <CardMedia
                image={data.media}
                style={{ ...styles.postMedia, height: '18vw' }}
              />
            ) : (
              <CardMedia
                style={{ ...styles.postMedia, height: '10vw' }}
                src={data.media}
                component="video"
                controls
              />
            )}
            <Typography variant="h3" style={styles.inputTitle}>
              Post Message
            </Typography>
            <TextField
              id="about-post"
              name="message"
              multiline
              rows={5}
              value={data.message}
              onChange={handleInputChange}
              variant="filled"
              sx={styles.postMsg}
              InputProps={{
                disableUnderline: true,
                sx: {
                  borderRadius: '16px',
                  backgroundColor: `${styleColors.base.almost_white_blue}`,
                  border: _.has(fieldsWithErrors, 'message')
                    ? '1px solid #d32f2f'
                    : 'none',
                  boxShadow: _.has(fieldsWithErrors, 'message')
                    ? '0 0 0 1px #d32f2f'
                    : 'none',
                },
              }}
            />
            {renderInputErrorMessage('message')}
          </Grid>
          <Grid item xs className="middle-col">
            <Typography variant="h3" style={styles.inputTitle}>
              Store
            </Typography>
            <TextField
              id="store-input"
              value={data.store}
              name="store"
              variant="filled"
              sx={styles.detailsInput}
              onChange={handleInputChange}
              InputProps={{
                disableUnderline: true,
                sx: {
                  borderRadius: '16px',
                  backgroundColor: `${styleColors.base.almost_white_blue}`,
                  border: _.has(fieldsWithErrors, 'store')
                    ? '1px solid #d32f2f'
                    : 'none',
                  boxShadow: _.has(fieldsWithErrors, 'store')
                    ? '0 0 0 1px #d32f2f'
                    : 'none',
                },
              }}
              error={_.has(fieldsWithErrors, 'store')}
            />
            {renderInputErrorMessage('store')}

            <Typography variant="h3" style={styles.inputTitle}>
              Region
            </Typography>
            <TextField
              id="region-input"
              name="region"
              value={data.region}
              onChange={handleInputChange}
              variant="filled"
              sx={styles.detailsInput}
              InputProps={{
                disableUnderline: true,
                sx: {
                  borderRadius: '16px',
                  backgroundColor: `${styleColors.base.almost_white_blue}`,
                  border: _.has(fieldsWithErrors, 'region')
                    ? '1px solid #d32f2f'
                    : 'none',
                  boxShadow: _.has(fieldsWithErrors, 'region')
                    ? '0 0 0 1px #d32f2f'
                    : 'none',
                },
              }}
              error={_.has(fieldsWithErrors, 'region')}
            />
            {renderInputErrorMessage('region')}
            <Typography variant="h3" style={styles.inputTitle}>
              Market
            </Typography>
            <TextField
              id="market-input"
              name="market"
              value={data.market}
              onChange={handleInputChange}
              variant="filled"
              sx={styles.detailsInput}
              InputProps={{
                disableUnderline: true,
                sx: {
                  borderRadius: '16px',
                  backgroundColor: `${styleColors.base.almost_white_blue}`,
                  border: _.has(fieldsWithErrors, 'market')
                    ? '1px solid #d32f2f'
                    : 'none',
                  boxShadow: _.has(fieldsWithErrors, 'market')
                    ? '0 0 0 1px #d32f2f'
                    : 'none',
                },
              }}
              error={_.has(fieldsWithErrors, 'market')}
            />
            {renderInputErrorMessage('market')}
            <Typography variant="h3" style={styles.inputTitle}>
              Page Name
            </Typography>
            <TextField
              id="page-name-input"
              name="page_name"
              value={data.page_name}
              onChange={handleInputChange}
              variant="filled"
              sx={styles.detailsInput}
              InputProps={{
                disableUnderline: true,
                sx: {
                  borderRadius: '16px',
                  backgroundColor: `${styleColors.base.almost_white_blue}`,
                  border: _.has(fieldsWithErrors, 'page_name')
                    ? '1px solid #d32f2f'
                    : 'none',
                  boxShadow: _.has(fieldsWithErrors, 'page_name')
                    ? '0 0 0 1px #d32f2f'
                    : 'none',
                },
              }}
              error={_.has(fieldsWithErrors, 'page_name')}
            />
            {renderInputErrorMessage('page_name')}
            <Typography variant="h3" style={styles.inputTitle}>
              Created By
            </Typography>
            <TextField
              id="author-input"
              name="author"
              value={data.author}
              onChange={handleInputChange}
              variant="filled"
              sx={styles.detailsInput}
              InputProps={{
                disableUnderline: true,
                sx: {
                  borderRadius: '16px',
                  backgroundColor: `${styleColors.base.almost_white_blue}`,
                  border: _.has(fieldsWithErrors, 'author')
                    ? '1px solid #d32f2f'
                    : 'none',
                  boxShadow: _.has(fieldsWithErrors, 'author')
                    ? '0 0 0 1px #d32f2f'
                    : 'none',
                },
              }}
              error={_.has(fieldsWithErrors, 'author')}
            />
            {renderInputErrorMessage('author')}
          </Grid>
          <Grid className="far-right-col" xs item>
            <POTDWhyItWorks
              whyWorksData={data.why_it_works}
              setWhyWorksData={(wiwData) =>
                setData({ ...data, why_it_works: wiwData })
              }
              langWithErrors={langWithErrors}
              setLangWithErrors={setLangWithErrors}
            />
            <Typography variant="h3" style={styles.inputTitle}>
              Start Date
            </Typography>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Tooltip
                  title={
                    data.disableDateForActivePotd &&
                    'You may not edit the start date of the currently active Post of the Day.'
                  }
                  placement="bottom"
                  leaveDelay={300}
                  arrow
                >
                  {/* this span is needed to show the tooltip because when the date picker is disabled/readOnly the tooltip won't show */}
                  <span style={{ paddingBottom: 30 }}>
                    <DatePicker
                      value={data.start_date}
                      onChange={(newValue) => handleDate(newValue)}
                      sx={styles.startDateInput}
                      readOnly={data.disableDateForActivePotd}
                      disablePast
                    />
                  </span>
                </Tooltip>
              </LocalizationProvider>
            </Grid>
            {dateWarning && renderDateErrorMessage('warning')}
            {dateIsInvalid && renderDateErrorMessage('invalidDate')}
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={styles.buttonRow}
        >
          <Grid item xs>
            <Button
              variant="contained"
              sx={styles.cancelButton}
              onClick={handleCancelClick}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item justifyContent="flex-end">
            <Button
              variant="contained"
              sx={styles.submitButton}
              onClick={handleSubmitClick}
            >
              {isUpdate ? 'Update' : 'Submit'}
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
POTDForm.propTypes = {
  data: PropTypes.shape({
    author: PropTypes.string,
    message: PropTypes.string,
    media: PropTypes.string,
    page_name: PropTypes.string,
    type: PropTypes.string,
    store: PropTypes.string,
    market: PropTypes.string,
    region: PropTypes.string,
    status: PropTypes.string,
    disableDateForActivePotd: PropTypes.bool,
    why_it_works: PropTypes.shape({}),
    start_date: PropTypes.shape({}),
  }).isRequired,
  setData: PropTypes.func.isRequired,
  potdList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSubmit: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
};

POTDForm.defaultProps = {
  isUpdate: false,
};
