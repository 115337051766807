import AppService from '../shared/App.api';

export default {
  getStores,
  getStoreTypes,
  getStoreServices,
  getStoreDetails,
  pauseStores,
  unpauseStores,
};

const storeDetailsUrl = '/stores';

let cachedStoreTypes;
let cachedStoreServices;

async function getStores() {
  const response = await AppService.get(`${storeDetailsUrl}/all`);
  return response;
}

async function getStoreTypes() {
  if (!cachedStoreTypes) {
    const response = await AppService.get(`${storeDetailsUrl}/types`);
    cachedStoreTypes = response.data;
  }
  return cachedStoreTypes;
}

async function getStoreServices() {
  if (!cachedStoreServices) {
    const response = await AppService.get(`${storeDetailsUrl}/services`);
    cachedStoreServices = response.data;
  }
  return cachedStoreServices;
}

async function getStoreDetails(storeId) {
  const response = await AppService.get(`${storeDetailsUrl}/store/${storeId}`);
  return response.data;
}

async function pauseStores(storeList) {
  const response = await AppService.postJson(
    `${storeDetailsUrl}/pause`,
    storeList
  );
  return response.data;
}

async function unpauseStores(storeList) {
  const response = await AppService.postJson(
    `${storeDetailsUrl}/unpause`,
    storeList
  );
  return response.data;
}
