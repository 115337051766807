/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import _ from 'lodash';

import config from '../../config';
import FormValidationsUtils from '../../shared/utils/formValidations.utils';
import PriorityGetStartedStepsForm from './PriorityGetStartedStepsForm';
import PriorityCallsToActionForm from './PriorityCallsToActionForm';
import PriorityLanguageCopy from '../PriorityLanguageCopy.class';

const { DEFAULT_LANGUAGE } = config;
const { required, childValidation } = FormValidationsUtils;

export default function PriorityLanguageCopyForm({
  onStateChanged,
  initialLanguageCopy,
  isValidating,
  isHidden,
  langKey,
  spacing,
}) {
  const [languageCopy, setLanguageCopy] = useState(
    initialLanguageCopy || new PriorityLanguageCopy(langKey)
  );
  const [validationErrors, setValidationErrors] = useState({});
  const [areChildrenValid, setAreChildrenValid] = useState({
    getStartedSteps: true,
    callsToAction: true,
  });

  function updateLanguage(keyToUpdate, newValue) {
    setLanguageCopy({
      ...languageCopy,
      [keyToUpdate]: newValue,
    });
  }

  function validate() {
    const newErrors = {};

    if (isValidating) {
      if (!required().isValid(languageCopy.title)) {
        newErrors.title = required().helperText;
      }
      if (!required().isValid(languageCopy.body)) {
        newErrors.body = required().helperText;
      }
      if (!childValidation().isValid(areChildrenValid)) {
        newErrors.children = childValidation().helperText;
      }
    }

    setValidationErrors(newErrors);
    return _.isEmpty(newErrors);
  }

  useEffect(() => {
    onStateChanged(languageCopy, validate());
  }, [languageCopy, areChildrenValid, isValidating]);

  return (
    <Grid
      item
      container
      xs={12}
      spacing={spacing}
      sx={isHidden ? { display: 'none' } : {}}
    >
      <Grid item xs={12}>
        <TextField
          label={`Title (${langKey})`}
          value={languageCopy.title}
          onChange={(e) => {
            updateLanguage('title', e.target.value);
          }}
          fullWidth
          error={_.has(validationErrors, 'title')}
          helperText={validationErrors.title}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label={`Body (${langKey})`}
          value={languageCopy.body}
          onChange={(e) => {
            updateLanguage('body', e.target.value);
          }}
          fullWidth
          multiline
          minRows="3"
          error={_.has(validationErrors, 'body')}
          helperText={validationErrors.body}
        />
      </Grid>
      <PriorityGetStartedStepsForm
        langKey={langKey}
        initialState={languageCopy.getStartedSteps}
        onStateChanged={(newState, isStateValid) => {
          updateLanguage('getStartedSteps', newState);
          setAreChildrenValid({
            ...areChildrenValid,
            getStartedSteps: isStateValid,
          });
        }}
        spacing={spacing}
        isValidating={isValidating}
      />
      <PriorityCallsToActionForm
        langKey={langKey}
        initialState={languageCopy.callsToAction}
        onStateChanged={(newState, isStateValid) => {
          updateLanguage('callsToAction', newState);
          setAreChildrenValid({
            ...areChildrenValid,
            callsToAction: isStateValid,
          });
        }}
        spacing={spacing}
        isValidating={isValidating}
      />
    </Grid>
  );
}

PriorityLanguageCopyForm.propTypes = {
  onStateChanged: PropTypes.func.isRequired,
  initialLanguageCopy: PropTypes.shape(PriorityLanguageCopy),
  isValidating: PropTypes.bool,
  langKey: PropTypes.string,
  isHidden: PropTypes.bool,
  spacing: PropTypes.number,
};

PriorityLanguageCopyForm.defaultProps = {
  initialLanguageCopy: null,
  isValidating: false,
  langKey: DEFAULT_LANGUAGE,
  isHidden: false,
  spacing: 1,
};
