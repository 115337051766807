import PriorityUpdateForm from './PriorityUpdateForm';

export default function PriorityCreateForm({
  onAPISuccess,
  onAPIError,
  spacing,
}) {
  return PriorityUpdateForm({
    initialPriority: null,
    onAPISuccess,
    onAPIError,
    spacing,
  });
}
