import React from 'react';
import PropTypes from 'prop-types';

const Sticker = ({ sticker, additionalStyles = {}, status }) => {
  const styles = {
    image: {
      width: '100%',
      height: '100%',
      maxHeight: '110px',
      minHeight: '70px',
      minWidth: '70px',
      maxWidth: '110px',
      objectFit: 'contain',
      ...additionalStyles,
    },
    selected: {
      boxShadow: '0 0 5px 3px #a3a3a3',
      borderRadius: '3px',
      backgroundColor: 'rgba(163, 163, 163, 0.55)',
      opacity: 1,
    },
    inactive: {
      opacity: 0.4,
    },
    active: {
      opacity: 1,
    },
  };

  return (
    <img
      src={sticker.image}
      alt={sticker.name}
      loading="lazy"
      style={{
        ...styles.image,
        opacity: 1,
        ...styles[status],
      }}
    />
  );
};

export default Sticker;

Sticker.propTypes = {
  sticker: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  additionalStyles: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  status: PropTypes.oneOf(['selected', 'inactive', 'active']),
};

Sticker.defaultProps = {
  additionalStyles: {},
  status: 'active',
};
