import moment from 'moment-timezone';
import _ from 'lodash';
import config from '../config';

const { getWalmartWeek, getWMWeekStartDates } = config;

// exporting fillInBlankCards and assignStatus for unit testing
export default {
  formatPotdList,
  fillInBlankCards,
  assignStatus,
};

/**
 * Takes POTD data and sorts, filters, adds status
 * @param {array} potds - array of scheduled, active, and previous POTD objects
 */
function formatPotdList(potds) {
  // passing in the current date as a param makes it easier to unit test
  const week_start_dates = getWMWeekStartDates(Date.now());

  // sort by start_date ascending
  let formattedPotds = _.sortBy(potds, ['start_date']);

  // make sure we only have potds from the start of the previous WM week until the end of next week
  formattedPotds = _.filter(formattedPotds, (potd) => {
    return (
      potd.start_date >= week_start_dates.previous_week &&
      potd.start_date <
        moment(week_start_dates.next_week).add(7, 'days').format()
    );
  });

  return formattedPotds;
}

/**
 * Pulls one card for each day, and adds the "noPotd" status cards for dates where there are no scheduled POTDs
 * @param {array} potds - array of potd objects sorted by start_date ASC
 * @returns array with potd objects for every day; if there's no potd scheduled for a day,
 *  the object will only have the fields: {status:'noPotd', start_date, walmart_week}.
 */
function fillInBlankCards(potds) {
  const week_start_dates = getWMWeekStartDates(Date.now());
  let datePointer = week_start_dates.previous_week;
  const filledPotdList = [];

  while (
    moment(datePointer).format() <
    moment(week_start_dates.next_week).add(7, 'days').format()
  ) {
    const date = datePointer;
    // this uses _.findLast because an active POTD can be edited to create a new version
    // with a more recent start date. In this case, we would want the POTD with the
    // most recent start_date on each day
    const potdMatch = _.findLast(potds, (potd) => {
      return moment(potd.start_date)
        .utc()
        .hour(0)
        .isSame(moment(date).utc().hour(0), 'day');
    });

    if (potdMatch) {
      filledPotdList.push(potdMatch);
    } else {
      filledPotdList.push({
        status: 'noPotd',
        start_date: moment(date).utc().hour(0).format(),
        walmart_week: getWalmartWeek(date),
      });
    }
    datePointer = moment(date).add(1, 'day').format();
  }
  return filledPotdList;
}

/**
 * Add a status to each potd based on date relative to the active POTD
 * @param {array} potds - array of POTDs or blanks for each day sorted by start_date ASC
 * @returns array with a status param appended to each potd
 */
function assignStatus(potds) {
  const potdsWithStatus = potds;

  // at this point, potds is a list ordered by start_date
  // this uses _.findLastIndex to find the POTD with the most recent start date relative to now
  const activePotd = _.findLastIndex(potds, (potd) => {
    return (
      Date.parse(potd.start_date) <= Date.now() && potd.status !== 'noPotd'
    );
  });

  _.forEach(potds, (potd, index) => {
    if (
      (index < activePotd && potd.status === 'noPotd') ||
      index < activePotd
    ) {
      // if it's in the past, mark as inactive, whether it had a POTD or not
      potdsWithStatus[index] = {
        ...potds[index],
        status: 'inactive',
      };
    } else if (potd.status === 'noPotd') {
      // noPotd status has already been assigned, so if it's not in the past leave status as is
      return true;
    } else if (index === activePotd) {
      // if it's the active POTD, mark as active
      potdsWithStatus[index] = {
        ...potds[index],
        status: 'active',
      };
    } else if (index > activePotd) {
      // if it's after the active POTD, mark as scheduled
      potdsWithStatus[index] = {
        ...potds[index],
        status: 'scheduled',
      };
    }

    return true;
  });

  return potdsWithStatus;
}
