import { Button, Card, CardContent, Dialog, Typography } from '@mui/material';
import React from 'react';
import { PropTypes } from 'prop-types';

export default function HelpModal({ open, setOpen }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Card
        style={{
          width: '100%',
          margin: 'auto',
          maxWidth: 600,
          height: 176,
          textAlign: 'right',
        }}
      >
        <CardContent>
          <Typography
            variant="h5"
            component="h2"
            style={{
              marginLeft: 15,
              fontWeight: 700,
              marginBottom: 10,
              textAlign: 'center',
            }}
          >
            Help
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            component="p"
            style={{
              fontSize: 15,
              marginBottom: 30,
              textAlign: 'center',
            }}
          >
            For help with the admin panel, please contact mylocalsocial@bn.co
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(false)}
            style={{ height: 36, width: 112, marginRight: 30 }}
          >
            Close
          </Button>
        </CardContent>
      </Card>
    </Dialog>
  );
}

HelpModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
