/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, TextField, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import PropTypes from 'prop-types';

import _ from 'lodash';

import config from '../../config';
import ObjectUtils from '../../shared/utils/object.utils';
import FormValidationsUtils from '../../shared/utils/formValidations.utils';
import PriorityLanguageCopy from '../PriorityLanguageCopy.class';

const { DEFAULT_LANGUAGE } = config;
const { isObjectBlank } = ObjectUtils;
const { required } = FormValidationsUtils;

/*
  If you are here because you want to generalize this behavior (adding/removing rows of inputs), 
  please read the comments in `PriorityGetStartedStepsForm.js` first.
*/

export default function PriorityCallsToActionForm({
  initialState,
  onStateChanged,
  isValidating,
  langKey,
  spacing,
}) {
  const [callsToAction, setCallsToAction] = useState(
    initialState || [PriorityLanguageCopy.initCallToAction()]
  );
  const [validationErrors, setValidationErrors] = useState([]);

  function validate() {
    const validator = required();
    const newErrors = [];

    callsToAction.forEach((cta, index) => {
      if (isObjectBlank(cta)) {
        return;
      }

      const ctaErrors = {};
      if (!validator.isValid(cta.copy)) {
        ctaErrors.copy = validator.helperText;
      }
      if (!validator.isValid(cta.link)) {
        ctaErrors.link = validator.helperText;
      }

      if (!_.isEmpty(ctaErrors)) {
        newErrors[index] = ctaErrors;
      }
    });

    setValidationErrors(newErrors);
    return _.isEmpty(newErrors); // Return a boolean indicating whether there are any errors or not
  }

  useEffect(() => {
    onStateChanged(callsToAction, validate());
  }, [callsToAction, isValidating]);

  function appendEmptyItem() {
    setCallsToAction([
      ...callsToAction,
      PriorityLanguageCopy.initCallToAction(),
    ]);
  }

  function updateItemAt(index, keyToUpdate, newValue) {
    const newCallsToAction = [...callsToAction];
    newCallsToAction[index][keyToUpdate] = newValue;
    setCallsToAction(newCallsToAction);
  }

  function removeItemAt(index) {
    const newCallsToAction = [...callsToAction];
    newCallsToAction.splice(index, 1);
    setCallsToAction(newCallsToAction);
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">Calls to Action ({langKey})</Typography>
      </Grid>

      {callsToAction.map((cta, index) => (
        <Grid key={`getStarted_${index}`} item container spacing={spacing}>
          <Grid item xs>
            <TextField
              label="Text"
              value={cta.copy}
              onChange={(e) => updateItemAt(index, 'copy', e.target.value)}
              fullWidth
              error={_.has(validationErrors[index], 'copy')}
              helperText={_.get(validationErrors[index], 'copy')}
            />
          </Grid>
          <Grid item xs>
            <TextField
              label="In-App or External Link"
              value={cta.link}
              onChange={(e) => updateItemAt(index, 'link', e.target.value)}
              fullWidth
              error={_.has(validationErrors[index], 'link')}
              helperText={_.get(validationErrors[index], 'link')}
            />
          </Grid>

          <Grid item xs="auto" display="flex" alignItems="center">
            <IconButton
              type="button"
              variant="text"
              color="error"
              size="small"
              onClick={() => removeItemAt(index)}
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button
          type="button"
          variant="text"
          color="success"
          startIcon={<Add />}
          size="small"
          onClick={() => appendEmptyItem()}
        >
          add {callsToAction.length ? 'another' : 'a'} call to action
        </Button>
      </Grid>
    </>
  );
}

PriorityCallsToActionForm.propTypes = {
  onStateChanged: PropTypes.func.isRequired,
  initialState: PropTypes.arrayOf(
    PropTypes.shape({
      copy: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  isValidating: PropTypes.bool,
  langKey: PropTypes.string,
  spacing: PropTypes.number,
};

PriorityCallsToActionForm.defaultProps = {
  initialState: null,
  isValidating: false,
  langKey: DEFAULT_LANGUAGE,
  spacing: 1,
};
