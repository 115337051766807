// React material-ui component that lists first_name, last_name, email, user_id, win, platform_id, and authorization
import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';

export default function UserDetails({ user }) {
  return (
    <>
      <Grid
        container
        direction="column"
        xs={6}
        style={{
          minHeight: '100vh',
          marginTop: 20,
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <Typography variant="h4" style={{ textAlign: 'center' }}>
          User Information
        </Typography>
        <Divider />
        <List
          sx={{
            fontSize: '20px',
          }}
        >
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="body" style={{ fontWeight: 'bold' }}>
                  First Name
                </Typography>
              }
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  {user.first_name}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem divider>
            <ListItemText
              primary={
                <Typography variant="body" style={{ fontWeight: 'bold' }}>
                  Last Name
                </Typography>
              }
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  {user.last_name}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="body" style={{ fontWeight: 'bold' }}>
                  Email
                </Typography>
              }
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  {user.email}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="body" style={{ fontWeight: 'bold' }}>
                  User ID
                </Typography>
              }
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  {user.user_id || 'Not Found'}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="body" style={{ fontWeight: 'bold' }}>
                  Win
                </Typography>
              }
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  {user.win || 'Not Found'}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="body" style={{ fontWeight: 'bold' }}>
                  Platform ID
                </Typography>
              }
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  {user.platform_id}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="body" style={{ fontWeight: 'bold' }}>
                  Authorization
                </Typography>
              }
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  {user.authorization}
                </Typography>
              }
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="body" style={{ fontWeight: 'bold' }}>
                  Login Information
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  <b>Last login:</b>{' '}
                  {user.login_info?.[0]?.createdAt || 'Not Found'}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  <b>Model:</b> {user.login_info?.[0]?.model || 'Not Found'}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  <b>OS:</b> {user.login_info?.[0]?.os || 'Not Found'}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  <b>OS Version: </b>
                  {user.login_info?.[0]?.os_version || 'Not Found'}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              secondary={
                <Typography variant="body" style={{ display: 'block' }}>
                  <b>MLS Version: </b>
                  {user.login_info?.[0]?.mft_version || 'Not Found'}
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Grid>
    </>
  );
}

UserDetails.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    user_id: PropTypes.string,
    win: PropTypes.string,
    platform_id: PropTypes.string.isRequired,
    authorization: PropTypes.string.isRequired,
    login_info: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        model: PropTypes.string.isRequired,
        os: PropTypes.string.isRequired,
        os_version: PropTypes.string.isRequired,
        mft_version: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};
