import React from 'react';
import PropTypes from 'prop-types';
import Unauthorized from '../auth/Unauthorized.page';

export default function AccessControl({
  requiredPermission,
  userPermissions,
  children,
}) {
  const hasPermission = userPermissions.some(
    (permission) => permission?.title === requiredPermission?.title
  );

  return hasPermission ? children : <Unauthorized />;
}

AccessControl.propTypes = {
  requiredPermission: PropTypes.number.isRequired,
  userPermissions: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  children: PropTypes.node.isRequired,
};
