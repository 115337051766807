import {
  Box,
  Container,
  Grid,
  Link,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import SpinningSpark from '../shared/components/SpinningSpark';
import StoreService from './Stores.api';
import LoggingService from '../shared/Logging.api';

const STORE_KEYS = [
  { key: 'address', label: 'Address' },
  { key: 'zip', label: 'Zip' },
  { key: 'state', label: 'State' },
  { key: 'timezone', label: 'Time Zone' },
  { key: 'store_type', label: 'Store Type' },
  { key: 'region', label: 'Region' },
  { key: 'market', label: 'Market' },
  { key: 'health_market', label: 'Health Market' },
];
const SOCIAL_CHANNELS = ['facebook', 'instagram', 'tiktok'];

export default function StoreDetails() {
  const { store_id } = useParams();
  const [storeInfo, setStoreInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const storeChannels = useMemo(
    () => SOCIAL_CHANNELS.filter((channel) => _.has(storeInfo, channel)),
    [storeInfo]
  );

  useEffect(() => {
    StoreService.getStoreDetails(store_id)
      .then(setStoreInfo)
      .catch((err) => {
        LoggingService.error('Error getting store details', err);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (_.isEmpty(storeInfo)) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {isLoading ? (
          <SpinningSpark isSpinning={isLoading} height="50" />
        ) : (
          <Typography align="center" variant="h5" padding={4}>
            Store not found!
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Container
      style={{
        minHeight: '100vh',
        marginTop: 20,
      }}
    >
      <Box
        sx={{
          paddingY: '32px',
        }}
      >
        <Typography align="center" variant="h5">
          {storeInfo.page_name}
        </Typography>
        <Typography align="center" variant="h6">
          {storeInfo.name}
        </Typography>
      </Box>

      <Grid container gap={8}>
        <Grid item>
          <List>
            {STORE_KEYS.map(({ key, label }) => (
              <ListItem key={key} sx={{ minWidth: '240px' }}>
                <ListItemText
                  primary={
                    <Typography variant="body" style={{ fontWeight: 'bold' }}>
                      {label}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body" style={{ display: 'block' }}>
                      {_.get(storeInfo, key, '--')}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid item>
          <List>
            {storeChannels.map((channel) => (
              <ListItem key={channel}>
                <ListItemText
                  primary={
                    <Typography
                      variant="body"
                      sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
                    >
                      {channel} Page
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body" style={{ display: 'block' }}>
                      <Link href={storeInfo[channel].page_url} target="_blank">
                        {storeInfo[channel].external_id}
                      </Link>
                    </Typography>
                  }
                />
              </ListItem>
            ))}
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    variant="body"
                    sx={{ fontWeight: 'bold', textTransform: 'capitalize' }}
                  >
                    Snapchat
                  </Typography>
                }
                secondary={
                  <Typography
                    variant="body"
                    style={{ display: 'block', textTransform: 'capitalize' }}
                  >
                    {storeInfo.has_snapchat ? 'Enabled' : 'Disabled'}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Container>
  );
}
