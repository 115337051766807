/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Typography,
  TextField,
  Select,
} from '@mui/material';
import PropTypes from 'prop-types';

import _ from 'lodash';

import Priority from '../Priority.class';
import StoreService from '../../stores/Stores.api';
import CommonUtils from '../../shared/utils/common.utils';
import FormValidationsUtils from '../../shared/utils/formValidations.utils';

const DELIMITER = ',';
const { arrayToString, stringToArray } = CommonUtils;
const { allowedChars } = FormValidationsUtils;

export default function PriorityTargetingForm({
  initialTargeting,
  onStateChanged,
  isValidating,
}) {
  const [targeting, setTargeting] = useState(
    initialTargeting || Priority.initTargeting()
  );
  function updateTargeting(keyToUpdate, newValue) {
    setTargeting({
      ...targeting,
      [keyToUpdate]: newValue,
    });
  }

  const [storeTypes, setStoreTypes] = useState([]);
  const [storeServices, setStoreServices] = useState([]);
  useEffect(() => {
    StoreService.getStoreTypes().then((types) => setStoreTypes(types));
    StoreService.getStoreServices().then((services) =>
      setStoreServices(services)
    );
  }, []);

  const [validationErrors, setValidationErrors] = useState({});
  function validate() {
    const newErrors = {};

    if (isValidating) {
      const onlyNums = allowedChars(`0-9 ${DELIMITER}`);
      const onlyChars = allowedChars(`a-zA-Z ${DELIMITER}`);

      const { storeNumbers, markets, states } = targeting;
      if (!onlyNums.isValid(storeNumbers.join(DELIMITER))) {
        newErrors.storeNumbers = onlyNums.helperText;
      }
      if (!onlyNums.isValid(markets.join(DELIMITER))) {
        newErrors.markets = onlyNums.helperText;
      }
      if (!onlyChars.isValid(states.join(DELIMITER))) {
        newErrors.states = onlyChars.helperText;
      }
    }

    setValidationErrors(newErrors);
    return _.isEmpty(newErrors);
  }

  useEffect(() => {
    onStateChanged(targeting, validate());
  }, [targeting, isValidating]);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">Targeting (optional)</Typography>
      </Grid>

      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="storeType_label">Store Type</InputLabel>
          <Select
            multiple
            labelId="storeType_label"
            label="Store Types"
            autoComplete="off"
            value={targeting.storeTypes}
            onChange={(e) => {
              const { value } = e.target;
              updateTargeting('storeTypes', value);
            }}
          >
            {storeTypes.map((storeType) => (
              <MenuItem key={`storeType_option_${storeType}`} value={storeType}>
                {storeType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="storeService_label">Store Service</InputLabel>
          <Select
            multiple
            labelId="storeService_label"
            label="Store Service"
            autoComplete="off"
            value={targeting.serviceDefinitionIds}
            onChange={(e) => {
              const { value } = e.target;
              updateTargeting('serviceDefinitionIds', value);
            }}
            MenuProps={{
              sx: {
                maxHeight: '50vh',
              },
            }}
          >
            {_.map(storeServices, (service) => (
              <MenuItem
                key={`storeService_option_${service.id}`}
                value={service.id}
              >
                {service.display_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="Regions"
          fullWidth
          value={arrayToString(targeting.regions, DELIMITER)}
          onChange={(e) =>
            updateTargeting('regions', stringToArray(e.target.value, DELIMITER))
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Market Numbers"
          fullWidth
          value={arrayToString(targeting.markets, DELIMITER)}
          onChange={(e) =>
            updateTargeting('markets', stringToArray(e.target.value, DELIMITER))
          }
          error={_.has(validationErrors, 'markets')}
          helperText={validationErrors.markets}
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          label="States"
          fullWidth
          value={arrayToString(targeting.states, DELIMITER)}
          onChange={(e) =>
            updateTargeting('states', stringToArray(e.target.value, DELIMITER))
          }
          error={_.has(validationErrors, 'states')}
          helperText={validationErrors.states}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Store Numbers"
          fullWidth
          value={arrayToString(targeting.storeNumbers, DELIMITER)}
          onChange={(e) =>
            updateTargeting(
              'storeNumbers',
              stringToArray(e.target.value, DELIMITER)
            )
          }
          error={_.has(validationErrors, 'storeNumbers')}
          helperText={validationErrors.storeNumbers}
        />
      </Grid>
    </>
  );
}

PriorityTargetingForm.propTypes = {
  initialTargeting: PropTypes.shape({
    storeTypes: PropTypes.arrayOf(PropTypes.string),
    serviceDefinitionIds: PropTypes.arrayOf(PropTypes.number),
    regions: PropTypes.arrayOf(PropTypes.string),
    markets: PropTypes.arrayOf(PropTypes.string),
    states: PropTypes.arrayOf(PropTypes.string),
    storeNumbers: PropTypes.arrayOf(PropTypes.string),
  }),
  onStateChanged: PropTypes.func.isRequired,
  isValidating: PropTypes.bool,
};

PriorityTargetingForm.defaultProps = {
  initialTargeting: null,
  isValidating: false,
};
