import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Typography,
  TextField,
} from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import { createStyles } from '@mui/styles';
import _ from 'lodash';
import styleColors from '../colors.styles';
import PrioritiesService from './Priorities.api';
import Priority from './Priority.class';
import SpinningSpark from '../shared/components/SpinningSpark';
import PrioritiesTable from './PrioritiesTable';
import Modal from '../shared/components/Modal';
import PriorityCreateForm from './forms/PriorityCreateForm';
import LoggingService from '../shared/Logging.api';

const styles = createStyles({
  header: {
    backgroundColor: styleColors.base.walmart_blue,
    color: '#FFF',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: 2,
    flexShrink: 1,
  },
});

export default function Priorities() {
  const [priorities, setPriorities] = useState(undefined);
  const [filter, setFilter] = useState('');
  const [areFuturePrioritiesHidden, setAreFuturePrioritiesHidden] =
    useState(false);
  const [isCreatingPriority, setCreatingPriority] = useState(false);

  function startCreatingPriority() {
    setCreatingPriority(true);
  }

  function onPriorityCreated(priority) {
    setPriorities([...priorities, Priority.attachSearchTerm(priority)]);
    setCreatingPriority(false);
  }

  useEffect(() => {
    PrioritiesService.getPriorities()
      .then((getResult) => {
        setPriorities(getResult.map(Priority.attachSearchTerm));
      })
      .catch((err) => LoggingService.error('Error getting priorities', err));
  }, []);

  return (
    <>
      <Grid container sx={styles.header} direction="row">
        <Grid>
          <Typography variant="h4"> Priorities </Typography>
        </Grid>
        {priorities && (
          <Grid flexGrow={1}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              paddingLeft={2}
            >
              <Grid>
                <Button
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={startCreatingPriority}
                  variant="contained"
                  color="secondary"
                  size="large"
                  startIcon={<Add />}
                >
                  Create
                </Button>
              </Grid>
              <Grid>
                <TextField
                  id="search"
                  label="Search all fields..."
                  variant="filled"
                  color="secondary"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="secondary" />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiFormLabel-root, .MuiFilledInput-root': {
                      color: 'white',
                    },
                    paddingRight: 2,
                  }}
                />
                <FormControlLabel
                  label="Hide future priorities"
                  labelPlacement="end"
                  control={
                    <Checkbox
                      color="secondary"
                      checked={areFuturePrioritiesHidden}
                      onChange={(e) =>
                        setAreFuturePrioritiesHidden(e.target.checked)
                      }
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      {!_.isEmpty(priorities) ? (
        <PrioritiesTable
          priorities={priorities}
          onPrioritiesUpdated={setPriorities}
          filter={filter}
          hideFuturePriorities={areFuturePrioritiesHidden}
        />
      ) : (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
        >
          <SpinningSpark isSpinning={_.isEmpty(priorities)} />
        </Box>
      )}

      <Modal
        isOpen={isCreatingPriority}
        setOpen={setCreatingPriority}
        title="Create Priority"
      >
        <PriorityCreateForm
          // eslint-disable-next-line react/jsx-no-bind
          onAPISuccess={onPriorityCreated}
          onAPIError={(err) =>
            LoggingService.error('Error creating priority', err)
          }
          spacing={2}
        />
      </Modal>
    </>
  );
}
