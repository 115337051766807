import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import styleColors from '../colors.styles';

const styles = {
  pageContainer: {
    backgroundColor: `${styleColors.base.walmart_blue}2b`,
    borderRadius: '33px 33px 33px 33px',
    opacity: 1,
    overflow: 'auto',
    paddingBottom: '20px',
  },
  pageHead: {
    backgroundColor: styleColors.base.walmart_blue,
    color: '#FFFFFF',
    textAlign: 'center',
    borderRadius: '33px 33px 0 0',
    padding: '1vh 10px',
  },
  potdTitle: {
    fontSize: '3rem',
  },
  potdButton: {
    backgroundColor: styleColors.base.spark_yellow,
    color: '#000',
    borderRadius: '12px',
    border: `1px solid ${styleColors.base.dark_gray}`,
    boxShadow: '0px 3px 6px #00000029',
    fontSize: '1.5rem',
    opacity: 1,
    overflow: 'hidden',
    padding: '1vh 1vw',
    '&:hover': {
      backgroundColor: styleColors.base.very_light_blue,
    },
  },
  childContainer: {
    padding: '2vw',
  },
};

export default function POTDContainer({ children, showAddPotdButton }) {
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        className="POTD-page-container"
        spacing={2}
        style={styles.pageContainer}
      >
        <Grid container sx={styles.pageHead} direction="row">
          <Grid item xs />
          <Grid item xs={8}>
            <Typography variant="h1" style={styles.potdTitle} xs={10}>
              {' '}
              Post of the Day{' '}
            </Typography>
          </Grid>
          <Grid item xs>
            {showAddPotdButton && (
              <Button
                variant="contained"
                size="large"
                sx={styles.potdButton}
                // eslint-disable-next-line no-alert
                onClick={() => navigate(`/potd/candidates`)}
              >
                {' '}
                Add POTD
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          className="POTD-child-container"
          style={styles.childContainer}
          xs={12}
        >
          {children}
        </Grid>
      </Grid>
    </>
  );
}

POTDContainer.defaultProps = {
  showAddPotdButton: false,
};

POTDContainer.propTypes = {
  children: PropTypes.node.isRequired,
  showAddPotdButton: PropTypes.bool,
};
