import moment from 'moment-timezone';
import config from './config.wmus.production';

const { getWMWeekStartDates } = config;

// TODO: testing the getWalmartWeek function will be part of WMTA-6363

// SCMX's walmart weeks start on different days, and follow a different fiscal year
// TODO: test SCMX-specific functions once added in WMTA-6346
describe('config functions for WMUS, WMCA, SCUS', () => {
  describe('getWMWeekStartDates', () => {
    it('should return the week start dates in a three week range in UTC', () => {
      jest.useFakeTimers().setSystemTime(new Date('2023-07-20').getTime());

      let start_dates = getWMWeekStartDates();
      expect(start_dates.current_week).toBe('2023-07-15T00:00:00Z');
      expect(start_dates.previous_week).toBe('2023-07-08T00:00:00Z');
      expect(start_dates.next_week).toBe('2023-07-22T00:00:00Z');

      jest.useFakeTimers().setSystemTime(new Date('2023-11-15').getTime());

      start_dates = getWMWeekStartDates();
      expect(start_dates.current_week).toBe('2023-11-11T00:00:00Z');
      expect(start_dates.previous_week).toBe('2023-11-04T00:00:00Z');
      expect(start_dates.next_week).toBe('2023-11-18T00:00:00Z');

      jest.useFakeTimers().setSystemTime(new Date('2023-12-19').getTime());

      start_dates = getWMWeekStartDates();
      expect(start_dates.current_week).toBe('2023-12-16T00:00:00Z');
      expect(start_dates.previous_week).toBe('2023-12-09T00:00:00Z');
      expect(start_dates.next_week).toBe('2023-12-23T00:00:00Z');
    });

    it('should return the week start dates as saturdays', () => {
      const start_dates = getWMWeekStartDates();

      // moment returns the days as numbers - 0 being Sunday and 6 being Saturday
      expect(moment(start_dates.current_week).day()).toBe(6);
      expect(moment(start_dates.previous_week).day()).toBe(6);
      expect(moment(start_dates.next_week).day()).toBe(6);
    });
  });
});
